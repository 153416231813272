import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import favorites from './main'
import { forceRepaint } from '../../../js/document/css'

const definition = {
  name: 'c-favorite-collector',
  props: [
    {
      name: 'count',
      type: 'number',
      attr: 'data-c-favorite-collector__count'
    }
  ]
}

/**
 * FavoriteCollector content
 *
 */
export default class FavoriteCollector extends Component {
  /**
   * Creates a new FavoriteCollector behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    // Update counter with current value
    this.setProp('count', favorites.itemsLength(), { silent: true })
    favorites.events.on('init', () => {
      this.setProp('count', favorites.itemsLength(), { silent: true })
    })

    // Bind favorites change event
    favorites.events.on('change', () => {
      const newCount = favorites.itemsLength()
      if (newCount === this.props.count) return
      this.element.classList.remove('is-incrementing', 'is-decrementing')
      forceRepaint(this.element)
      this.element.classList.add(newCount > this.props.count ? 'is-incrementing' : 'is-decrementing')
      this.setProp('count', favorites.itemsLength())
    })
  }
}

registerComponent(FavoriteCollector, definition.name, definition)
