import { fromCamelCase } from '../../../js/helpers/string'

/**
 * The CheckboxData contains all data needed to hydrate a Checkbox view
 *
 * @typedef {Object}          CheckboxData
 *
 * @property {String}         id                - The element id
 * @property {String}         name              - The element/group name
 * @property {String}         value             - The form element value
 * @property {String}         text              - Option text
 * @property {String}        [size]
 * @property {Boolean}       [checked]
 * @property {Boolean}       [halfChecked]
 * @property {Boolean}       [disabled]
 * @property {Boolean}       [unresolved]
 * @property {Boolean}       [hasChild]
 * @property {String}        [count]            - The count additional text
 * @property {String}        [additionalText]   - The additional text
 * @property {String}        [state]            - Component state, eg. validation
 * @property {String}        [extraClasses]     - Extra classes to append to the view
 * @property {Object}        [dataset]          - Extra attributes
 */

export const defaultCheckboxData = {
  id: '',
  name: '',
  value: '',
  text: '',
  size: '',
  checked: false,
  halfChecked: false,
  disabled: false,
  unresolved: false,
  hasChild: false,
  link: null,
  html: ''
}

export const CheckboxTemplate = (d) => `
<div class="c-checkbox
    ${d.required ? 'is-required' : ''}
    ${d.size ? `c-checkbox--${d.size}` : ''}
    ${d.disabled ? 'is-disabled' : ''}
    ${d.state ? `has-${d.state}` : ''}
    ${d.halfChecked ? 'is-half-checked' : ''}
    ${d.unresolved ? 'is-unresolved' : ''}
    ${d.hasChild ? 'has-child' : ''}
    ${d.dataset && d.dataset.illustrationSrc ? 'c-checkbox--with-illustration' : ''}
    ${d.extraClasses ? d.extraClasses : ''}">
  <input class="c-checkbox__input"
      type="checkbox"
      id="${d.id}"
      name="${d.name}"
      value="${d.value}"
      data-text="${d.text.replace(/"/g, '\'')}"
      ${d.disabled ? 'disabled=""' : ''}
      ${d.checked ? 'checked=""' : ''}
      ${d.required ? 'required=""' : ''}
      ${d.dataset ? Object.keys(d.dataset).map(k => `data-${fromCamelCase(k)}="${d.dataset[k]}"`).join(' ') : ''}/>
  <label class="c-checkbox__label" for="${d.id}">
    ${d.dataset && d.dataset.illustrationSrc
      ? `<img class="c-checkbox__illustration-image" src="${d.dataset.illustrationSrc}" alt="${d.dataset.illustrationAlt}" />
      <div class="c-checkbox__element-container">`
      : ''}
    <div class="c-checkbox__element"></div>
    <div class="c-checkbox__text-wrapper">
      <div class="c-checkbox__text-content ${d.html ? 'c-checkbox__text-custom-content' : ''}">
        ${d.dataset && d.dataset.imageSrc ? `<img class="c-radio-button__image" src="${d.dataset.imageSrc}" alt="${d.dataset.imageAlt}" />` : ''}
        ${d.html
          ? d.html
          : `<span class="c-checkbox__text">
              ${d.text}
              ${d.link ? `<a href="${d.link.href}" target="${d.link.target}">${d.link.text}</a>` : ''}
            </span>
            ${d.count ? `<span class="c-checkbox__count">${d.count}</span>` : ''}`}
      </div>
      ${d.additionalText ? `<span class="c-checkbox__additional-text">${d.additionalText}</span>` : ''}
    </div>
    ${d.dataset && d.dataset.illustrationSrc ? '</div>' : ''}
  </label>
</div>`
