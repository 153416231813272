import Component from '../../../js/core/component/component'
import { defaultCheckboxData } from '../checkbox/c-checkbox.template'
import { defaultRadioButtonData } from '../radio-button/c-radio-button.template'
import { BtnTemplate } from '../btn/c-btn.template'
import { elementFromString } from '../../../js/document/html-helper'
import { registerComponent } from '../../../js/core/component/component-directory'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { ChoicelistMessagesTemplate } from './c-choice-list__messages.template'
import registeredEvents from '../../../js/helpers/registered-events'
import { choiceListEvents } from '../../../js/document/event-types'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { renderOption, renderRichOption } from './c-choice-list.template'

const globalLocales = register(`window.sundio.i18n.${language}.global`)

const attr = {
  track: 'data-track'
}

const definition = {

  name: 'c-choice-list',
  props: [
    {
      name: 'method',
      type: 'string',
      attr: 'data-c-choice-list__method',
      allowedValues: [
        'single',
        'multiple',
        'dynamic'
      ]
    },
    {
      name: 'variant',
      type: 'string',
      attr: 'data-c-choice-list__variant',
      allowedValues: [
        'default',
        'boxed'
      ],
      defaultValue: 'default'
    },
    {
      name: 'options',
      type: 'collection'
    },
    {
      name: 'label',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'required',
      type: 'boolean',
      attr: 'required',
      defaultValue: false
    },
    {
      name: 'showInSlider',
      type: 'boolean',
      attr: 'data-c-choice-list__show-in-slider',
      defaultValue: false
    }
  ]
}

const ChoiceListExtraClasses = {
  extraClasses: 'c-choice-list__option '
}

/**
 * Choice list content
 *
 */
export default class ChoiceList extends Component {
  /**
   * Creates a new choice list behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.silentErrors = true

    this.selectedValues = this.getSelectedValuesFromOptions()

    this.attachEvents()

    // Update all elements when method changes
    this.events.on('propChanged', (changes) => {
      if (changes.name === 'method') { this.setProp('options', this.props.options, { silent: true, forceUpdate: true }) }
    })

    this.messagesElement = this.element.querySelector('.c-choice-list__messages')
    this.messageRequired = null
    if (element.getAttribute('data-message-required')) {
      this.messageRequired = element.getAttribute('data-message-required')
    } else {
      this.messageRequired = this.getOptionsElement().getAttribute('data-message-required')
    }
    this.labelElement = this.element.querySelector('.c-choice-list__label')

    this.element[definition.name].getSelectedValues = (this.getSelectedValues).bind(this)
    this.element[definition.name].getReducedSelectedValuesFromOptions = (this.getReducedSelectedValuesFromOptions).bind(this)
    this.element[definition.name].disableComponent = (this.disableComponent).bind(this)
    this.element[definition.name].enableComponent = (this.enableComponent).bind(this)
    this.element[definition.name].validate = this.validate.bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)
    this.element[definition.name].styleValidity = this.styleValidity.bind(this)
    this.element[definition.name].getValidationMessages = this.getValidationMessages.bind(this)

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  attachEvents () {
    const events = []
    const items = this.element.querySelectorAll('.c-choice-list__option > .c-radio-button__input , .c-choice-list__option > .c-checkbox__input')
    items.forEach(item => {
      events.push([item, { change: (ev) => this.onChange(ev) }])
    })

    if (this.clearElement) {
      events.push([this.clearElement, { click: (ev) => this.onClear(ev) }])
    }
    domEventsHelper.attachEvents(events, definition.name)
  }

  getOptionsElement () {
    this.optionsElement = this.optionsElement || this.element.querySelector('.c-choice-list__options')
    return this.optionsElement
  }

  getShowInSlider () {
    return this.element.hasAttribute('data-c-choice-list__show-in-slider') || false
  }

  /**
   * Get the whole array of options from current DOM
   *
   * @return {RadioButtonData[]|CheckboxData[]} The parsed options data
   */
  getOptions () {
    const optionsSelector = this.getProp('method') === 'single' ? '.c-radio-button__children' : '.c-checkbox__children'
    const optionItemElements = this.getShowInSlider() ? [...this.getOptionsElement().querySelectorAll('.c-slider__item .c-choice-list__option-rich')] : [...this.getOptionsElement().children]

    return optionItemElements.filter(el => !el.matches(optionsSelector))
      .map(el => ChoiceList.getOptionDataFromOptionElement(el))
  }

  /**
   * Disable the component
   */
  disableComponent () {
    this.setProp('options', this.getProp('options').map((option) => ({ ...option, disabled: true })))
  }

  /**
   * Enable the component
   */
  enableComponent () {
    this.setProp('options', this.getProp('options').map((option) => ({ ...option, disabled: false })))
  }

  /**
  * Set the label text
  */
  setLabel (text) {
    if (this.labelElement) {
      this.labelElement.innerText = text
    }
  }

  /**
  * Get the label text
  */
  getLabel () {
    return this.labelElement ? this.labelElement.innerText : ''
  }

  setSliderOptions (input) {
    const mappedOptions = this.getProp('options').map((option) => ({ ...option, checked: (option.value === input.value) }))
    this.setProp('options', mappedOptions, { isSlider: true })

    const optionItemElements = [...this.getOptionsElement().querySelectorAll('.c-slider__item .c-choice-list__option-rich')]

    optionItemElements.forEach(optionItemElement => {
      const inputElement = optionItemElement.querySelector('input')
      if (inputElement) {
        if (inputElement.value === input.value && input.checked) {
          optionItemElement.classList.add('is-checked')
        } else {
          inputElement.checked = false
          optionItemElement.classList.remove('is-checked')
        }
      }
    })
  }

  /**
   * Set the array of options the choice-list will have
   *
   * @param {Object[]} items - Items to be set
   * @param {Object} options - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   * @param {Boolean} options.isSlider - If true, does forceRepaint
   * @param {Object[]} oldItems - Old value of the items
   */
  setOptions (items, options, oldItems) {
    items = items || []
    if (!options.isSlider) {
      this._flushOptionElements()
      const itemsToElements = (items) => {
        const fragment = document.createDocumentFragment()
        items.forEach(item => {
          fragment.appendChild(this._newOptionElement(item))
          if (item.items) {
            const subFragment = elementFromString('<div class="c-checkbox__children"></div>')
            subFragment.appendChild(itemsToElements(item.items))
            fragment.appendChild(subFragment)
          }
        })
        return fragment
      }
      this.getOptionsElement().appendChild(itemsToElements(items))
    }
    this.updateSelectedOptionsAndElements()
    this.selectedValues = this.getSelectedValuesFromOptions()
    if (options.isDynamic && this.props.method === 'single' && this.selectedValues.length > 0) {
      this.clearElement = this._clearButtonElement(globalLocales)
      this.element.appendChild(this.clearElement)
    }
    this.attachEvents()
    if (!options.silent) {
      this.events.emit('changeOptions', this.props.options, oldItems, this.props.method)
    }
  }

  /**
   * Get selected values
   *
   * @return {String[]} values
   */
  getSelectedValues () {
    return this.selectedValues
  }

  /**
   * Get selected values from options prop
   *
   * @return {String[]} values
   */
  getSelectedValuesFromOptions () {
    const getSelectedOptions = (options) => {
      const selectedValues = []
      options
        .forEach(option => {
          if (option.checked) selectedValues.push(option.value)
          if (option.items) selectedValues.push(...getSelectedOptions(option.items))
        })
      return selectedValues
    }
    return getSelectedOptions(this.props.options)
  }

  /**
   * Get reduced selected values from options prop
   * - Reduced means on nested choice lists, lower level values will be skipped if parent is selected
   *
   * @return {String[]} values
   */
  getReducedSelectedValuesFromOptions () {
    const getSelectedOptions = (options) => {
      const selectedValues = []
      options
        .forEach(option => {
          if (option.checked) selectedValues.push(option.value)
          if (!option.checked && option.items) selectedValues.push(...getSelectedOptions(option.items))
        })
      return selectedValues
    }
    return getSelectedOptions(this.props.options)
  }

  /**
   * Handle element change events
   */
  onChange (ev) {
    const input = ev.target
    this.events.emit('click', input)
    // Handle single choice list
    if (this.getProp('method') === 'single') {
      if (this.getShowInSlider()) {
        this.setSliderOptions(input)
        return
      }
      this.setProp('options', this.getProp('options').map((option) => ({ ...option, checked: (option.value === input.value) })))
      return
    }

    // Handle multiple (and nested) choice list
    const matchOption = this.findOptionByValue(input.value)
    if (!matchOption) return
    matchOption.checked = input.checked

    if (this.isNestedChoiceList()) {
      const getOptionLevel = (el) => {
        let level = 1
        do {
          if (el === this.getOptionsElement()) return level
          if (el.matches('.c-checkbox__children')) level++
          el = el.parentElement || el.parentNode
        } while (el !== null && el !== this.getOptionsElement() && el.nodeType === 1)
        return level
      }
      const optionLevel = getOptionLevel(input)
      const option = this.findOptionByValue(input.value)
      this.setEveryChildSelected(option, input.checked)
      if (optionLevel === 1) this.findElementByValue(input.value).classList.remove('is-half-checked')
      if (optionLevel > 1) this.updateSelectedOptionsAndElements()
    }

    this.selectedValues = this.getSelectedValuesFromOptions()
    this.events.emit('changeOptions', this.props.options)
    ev.stopPropagation()
  }

  onClear (ev) {
    this.props.options.forEach(option => { option.checked = false })
    this.selectedValues = this.getSelectedValuesFromOptions()
    this.events.emit('changeOptions', this.props.options)
  }

  isNestedChoiceList () {
    let nested = false
    this.props.options.forEach(option => {
      if (nested) return
      nested = !!option.items
    })
    return nested
  }

  setEveryChildSelected (option, checked = true) {
    if (!option.items) return
    option.items.forEach(subOption => {
      this.setOptionChecked(subOption, checked)
      if (subOption.items) this.setEveryChildSelected(subOption, checked)
    })
  }

  /**
   * Updates selected options and elements with nested values
   */
  updateSelectedOptionsAndElements () {
    const hasAnySelectedChild = (option) => {
      if (!option.items) return false
      let childSelected = false
      option.items.forEach(subOption => {
        if (childSelected) return
        if (subOption.checked) childSelected = true
      })
      return childSelected
    }
    const hasEverySelectedChild = (option) => {
      if (!option.items) return false
      let everySelected = true
      option.items.forEach(subOption => {
        if (!everySelected) return
        if (!subOption.checked) everySelected = false
      })
      return everySelected
    }

    this.props.options.forEach(option => {
      if (!option.items) return
      if (hasEverySelectedChild(option)) {
        this.setOptionChecked(option)
        return
      }
      this.setOptionHalfChecked(option, hasAnySelectedChild(option))
    })

    return this
  }

  /**
   * Set checked prop to certain option
   */
  setOptionChecked (option, checked = true) {
    if (option.checked === checked) return
    option.checked = checked
    this.findInputByValue(option.value).checked = checked
    if (this.isNestedChoiceList()) {
      this.findElementByValue(option.value)
        .classList.remove('is-half-checked')
    }
  }

  /**
   * Set checked prop to certain option
   */
  setOptionHalfChecked (option, halfChecked = true) {
    option.checked = false
    this.findInputByValue(option.value).checked = false
    this.findElementByValue(option.value)
      .classList[halfChecked ? 'add' : 'remove']('is-half-checked')
  }

  /**
   * Find an option by value
   */
  findOptionByValue (value) {
    const findOptionByValue = (value, options) => {
      let matchOption
      options.forEach(option => {
        if (option.value === value) matchOption = option
        if (!matchOption && option.items) matchOption = findOptionByValue(value, option.items)
      })
      return matchOption
    }
    return findOptionByValue(value, this.props.options)
  }

  /**
   * Find an element by value
   */
  findElementByValue (value) {
    const input = this.findInputByValue(value)
    return input
      ? input.closest('.c-checkbox, .c-radio-button')
      : input
  }

  /**
   * Find an input by value
   */
  findInputByValue (value) {
    return this.getOptionsElement().querySelector(`input[value="${value}"]`)
  }

  /**
   * Flush all option elements for this component
   *
   * @return {ChoiceList} self instance
   */
  _flushOptionElements () {
    while (this.getOptionsElement().firstChild) {
      this.getOptionsElement().removeChild(this.getOptionsElement().firstChild)
    }
    if (this.messagesElement) {
      while (this.messagesElement.firstChild) {
        this.messagesElement.removeChild(this.messagesElement.firstChild)
      }
    }
    if (this.clearElement) {
      this.clearElement.remove()
    }
    this.element.classList.remove('has-error')
    return this
  }

  /**
   * Creates an option element from option data
   *
   * @param {RadioButtonData|CheckboxData} option - The data to create the view with
   *
   * @return {HTMLElement}
   *
   */
  _newOptionElement (option) {
    const checkBoxData = { ...defaultCheckboxData, ...ChoiceListExtraClasses }
    const radioButtonData = { ...defaultRadioButtonData, ...ChoiceListExtraClasses }
    const d = { method: this.props.method, id: this.element.id }

    return elementFromString(
      option.isRichOption
        ? renderRichOption(d, option, radioButtonData, checkBoxData)
        : renderOption(d, option, radioButtonData, checkBoxData)
    )
  }

  _clearButtonElement (locales) {
    return elementFromString(
      BtnTemplate({
        variant: 'flat-neutral',
        attributes: { 'data-c-choice-list-action': 'clear' },
        text: locales.clearFilter,
        extraClasses: 'c-choice-list__dynamic-clear-btn'
      }))
  }

  /**
   * Gets the option data from an existent element, RadioButton or Checkbox
   *
   * @param {HTMLElement} optionElement - The option element to get data from
   *
   * @return {RadioButtonData|CheckboxData}
   *
   */
  static getOptionDataFromOptionElement (optionElement) {
    const input = optionElement.querySelector('input')
    const count = optionElement.querySelector('.c-checkbox__count, .c-radio-button__count')
    const additionalText = optionElement.querySelector('.c-checkbox__additional-text, .c-radio-button__additional-text')
    const extraContent = optionElement.querySelector('[data-c-radio-button__extra-content]')
    const extraRichContentElement = optionElement.querySelector('[data-choice-list__option-rich-item__content]')
    const children = optionElement.nextElementSibling && optionElement.nextElementSibling.matches('.c-checkbox__children')
    const highlightText = optionElement.querySelector('.c-radio-button__highlight-text')
    const htmlContent = optionElement.querySelector('.c-checkbox__text-custom-content')
    const isRichOption = optionElement.classList.contains('c-choice-list__option-rich')

    if (!input.checked) { optionElement.classList.remove('is-checked') }

    return {
      id: input.id,
      name: input.name,
      value: input.value,
      text: input.dataset.text,
      checked: input.checked,
      disabled: optionElement.classList.contains('is-disabled'),
      hasChild: !!children,
      unresolved: optionElement.classList.contains('is-unresolved'),
      count: count ? parseInt(count.textContent) : undefined,
      additionalText: additionalText ? (additionalText.textContent || additionalText.innerHTML) : undefined,
      dataset: { ...input.dataset, text: undefined },
      highlightText: highlightText ? highlightText.textContent : undefined,
      items: children
        ? [...optionElement.nextElementSibling.children]
            .filter(el => !el.matches('.c-checkbox__children'))
            .map(el => ChoiceList.getOptionDataFromOptionElement(el))
        : undefined,
      validity: input.validity,
      messageRequired: input.getAttribute('data-message-required'),
      showExtraContentDiv: !!extraContent,
      extraClasses: !isRichOption ? [...optionElement.classList].join(' ') : '',
      extraClassesForRichOption: isRichOption ? [...optionElement.classList].join(' ') : '',
      isRichOption,
      extraRichContent: extraRichContentElement ? extraRichContentElement.innerHTML : undefined,
      html: htmlContent ? htmlContent.innerHTML : undefined
    }
  }

  enableErrors () {
    this.silentErrors = false
  }

  validate (validateOnly = false) {
    let isValid = true
    const elements = this.getOptions()
    if (this._isSingleSelectionChoceList()) {
      if (this.props.required) {
        const selectedElement = elements.find(element => element.checked)
        isValid = !!(selectedElement)
      }
    } else {
      const minlength = this.element.getAttribute('minlength')
      if (minlength) {
        isValid = parseInt(minlength) <= elements.filter(element => element.checked).length
      } else {
        elements.forEach(element => {
          const elementIsValid = element.validity.valid
          isValid = elementIsValid ? isValid : false
        })
      }
    }

    let messages = []
    if (!validateOnly && !this.silentErrors) {
      if (!isValid) {
        messages = this.getValidationMessages()
      }
      this.styleValidity(isValid, messages)
    }

    const firstOptionElement = this.getOptionsElement().querySelector('input')

    const validationObject = {
      isValid,
      errorMessages: messages,
      fieldName: firstOptionElement ? firstOptionElement.name : '',
      fieldType: firstOptionElement ? firstOptionElement.type : ''
    }
    if (!isValid) {
      this.events.emit(choiceListEvents.CHOICE_LIST_VALIDATION_ERROR, validationObject)
    }
    return validationObject
  }

  getValidationMessages () {
    const messages = []
    let messageRequired = ''
    if (this.props.method === 'single') {
      const firstOption = this.getOptions()[0]
      if (this.messageRequired) {
        messageRequired = this.messageRequired
      } else if (firstOption && firstOption.messageRequired) {
        messageRequired = firstOption.messageRequired
      }
    } else if (this.messageRequired) {
      messageRequired = this.messageRequired
    }
    messages.push(messageRequired)
    return messages
  }

  styleValidity (isValid, messages) {
    if (!isValid) {
      this.element.classList.add('has-error')
      Array.from(this.getOptionsElement().children).forEach(element => {
        element.classList.add('has-error')
      })
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ChoicelistMessagesTemplate(messages)
      }
    } else {
      this.element.classList.remove('has-error')
      Array.from(this.getOptionsElement().children).forEach(element => {
        element.classList.remove('has-error')
      })
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ''
      }
    }
  }

  _isSingleSelectionChoceList () {
    return this.props.method === 'single'
  }
}

registerComponent(ChoiceList, definition.name, definition)
