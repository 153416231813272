/**
 * C-Dropdown Helper for the Dropdown template
 */

/**
  *
  * @param {options} options from dropdown
  * @returns {boolean} if any option is selected
  */
export const isAnyOptionSelected = function (options) {
  let anySelected = false
  if (options) {
    anySelected = options.some(o => o !== null && o.selected)
  }
  return anySelected
}

/**
  *
  * @param {option} option from dropdown
  * @returns {String} content with the rendered items
  */
export const selectedItemSingle = function (option) {
  return `<span class="c-dropdown__multiple-item" ${option.value ? 'data-value=' + option.value : ''}>${option.text ? option.text : ''}</span>`
}

/**
  *
  * @param {options} options from dropdown
  * @returns {String} content with the rendered items
  */
export const renderSelectedItemsCompact = function (options) {
  let selectedItems = ''
  if (options) {
    options.forEach(option => {
      if (option.selected) {
        selectedItems += selectedItemSingle(option)
      }
      if (option.nestedOptions != null && option.nestedOptions.some(n => n.selected)) {
        option.nestedOptions.forEach(n => {
          if (n.selected) {
            selectedItems += selectedItemSingle(n)
          }
        })
      }
    })
  }
  return selectedItems
}

/**
  *
  * @param {options} option from dropdown (item)
  * @returns {bool} if item is half checked
  */
export const isHalfChecked = function (option) {
  let isHalfChecked = false
  if (option.selected && option.nestedOptions != null && option.nestedOptions.some(n => n.selected) && !option.NestedOptions.every(n => n.selected)) {
    isHalfChecked = true
  }
  return isHalfChecked
}

/**
  *
  * @param {options} dropdownId dropdown's id
  * @param {options} indexParent index from the parent loop it is in
  * @param {options} options from dropdown
  * @returns {Array} All content in a item nested option
  */
export const mapNestedOptionsToCheckboxViewModels = function (dropdownId, indexParent, options) {
  const nestedOptions = []
  if (options) {
    let index = 0
    options.forEach(option => {
      const item = {
        value: option.value,
        text: option.text,
        disabled: option.disabled,
        checked: option.selected,
        id: dropdownId + 'choice-list' + indexParent + 'nested-item' + index,
        name: dropdownId + 'choice-list' + indexParent + 'nested-items',
        count: option.count
      }
      nestedOptions.push(item)
      index++
    })
  }
  return nestedOptions
}

/**
  *
  * @param {options} dropdownId dropdown's id
  * @param {options} options from dropdown
  * @returns {Array} All the items in the choicelist
  */
export const buildChoiceListItem = function (options, dropdownId) {
  const choiceListItems = []
  if (options) {
    let index = 0
    options.forEach(option => {
      const item = {
        value: option.value,
        text: option.text,
        disabled: option.disabled,
        checked: option.selected,
        id: dropdownId + '-' + index,
        name: dropdownId + '-choice-list',
        items: mapNestedOptionsToCheckboxViewModels(dropdownId + '-nested', index, option.nestedOptions),
        isHalfChecked: isHalfChecked(option),
        count: option.count
      }
      choiceListItems.push(item)
      index++
    })
  }
  return choiceListItems
}
