import { TitleTemplate } from '../../components/title/c-title.template'
import { TextboxTemplate } from '../textbox/c-textbox.template'
import { DropdownTemplate } from '../dropdown/c-dropdown.template'
import { DateSelectorTemplate } from '../date-selector/c-date-selector.template'
import { ChoiceListTemplate, defaultChoiceListData } from '../choice-list/c-choice-list.template'
import { PhoneInputTemplate } from '../phone-input/c-phone-input.template'

/**
 * The FormFieldset contains all data needed to hydrate a Form Fieldset view
 *
 * @typedef {Object}          FormFieldsetData
 *
 * @property {String}         title                 - Title of the field set
 * @property {String}         extraClasses          - Extra classes to be rendered
 * @property {String}         html                  - Content of the field set
 * @property {String}         components            - List of components to render automatically in replacement of html field
 */
export const defaultFormFieldsetData = {
}

export const FormFieldsetTemplate = (d) => {
  d = { ...defaultFormFieldsetData, ...d }
  return `
    <div class="c-form__fieldset ${d.extraClasses ? d.extraClasses : ''}">
        ${d.title || d.description
          ? `<div class="c-form__fieldset-title-container">
              ${d.title
                ? `${TitleTemplate({
                  text: d.title,
                  size: 'tiny',
                  variant: ''
                })}`
                : ''}
                
              ${d.description
                  ? `<div class="c-form__fieldset-description">${d.description}</div>`
                  : ''}
          </div>`
        : ''}

        <div class="c-form__fieldset-container">

        ${d.html ? d.html : ''}
   
        ${d.components
            ? d.components.map(component =>
                FieldTemplate(component)
                ).join('')
            : ''}
        </div>
    </div>
    `
}

const defaultFieldData = {
  size: 'small',
  data: {
    extraClasses: ''
  }
}

const FieldTemplate = (d) => {
  d = { ...defaultFieldData, ...d }
  d.data.extraClasses = d.data.extraClasses + ' c-form__item c-form__item--' + d.size

  return `
    ${d.type === 'c-textbox'
    ? TextboxTemplate(d.data)
    : ''}
  
    ${d.type === 'c-dropdown'
    ? DropdownTemplate(d.data)
    : ''}
  
    ${d.type === 'c-date-selector'
    ? DateSelectorTemplate(d.data)
    : ''}
  
    ${d.type === 'c-choice-list'
    ? ChoiceListTemplate({ ...defaultChoiceListData, ...d.data })
    : ''}

    ${d.type === 'c-phone-input'
    ? PhoneInputTemplate(d.data)
    : ''}
  
  `
}
