/**
 * The FloatingBoxHeaderData contains all data needed to hydrate the view
 *
 * @typedef {Object}          FloatingBoxHeaderData
 *
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 *
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultFloatingBoxHeaderData = {
  extraClasses: '',
  attributes: {}
}

export const FloatingBoxHeaderTemplate = (d, content) => {
  d = { ...defaultFloatingBoxHeaderData, ...d }
  return `<div class="c-floating-box__header
    ${d.extraClasses ? d.extraClasses : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${content}
</div>`
}
