import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import favorites from './main'

const definition = {
  name: 'c-favorite-cleaner'
}

/**
 * FavoriteCleaner content
 *
 */
export default class FavoriteCleaner extends Component {
  /**
   * Creates a new FavoriteCleaner behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    // Bind click event
    const btn = element.querySelector('.c-favorite-cleaner')
    const search = document.querySelector('.fr-search')
    const emptyFavorites = document.querySelector('.fr-empty-favorites')
    btn && btn.addEventListener('click', async () => {
      await favorites.reset([])
      btn.classList.add('u-hidden')
      search && search.classList.add('u-hidden')
      emptyFavorites && emptyFavorites.classList.remove('u-hidden')
    })
  }
}

registerComponent(FavoriteCleaner, definition.name, definition)
