import Collection from '../../../js/core/collection'
import storage from '../../../js/document/web-storage'
import { fetchJsonData, fetchJsonDataAndHeaders } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'

const EventEmitter = require('eventemitter3')
const componentQueries = {
  favoriteToggle: 'data-product-favourites',
  addUrl: 'data-product-favourites-add-url',
  removeUrl: 'data-product-favourites-remove-url',
  removeAllUrl: 'data-product-favourites-remove-all-url',
  getUrl: 'data-product-favourites-get-url',
  loginUrl: 'data-product-favourites-login-url'
}
const cookieName = 'favs'
const cookieApiName = `${cookieName}_api`
const cookieMigratedName = `${cookieName}_migrated`

const cookieSettings = {
  expirationDays: 365,
  path: '/'
}

class Favorites {
  /**
   * Favorites manager
   *
   * @constructor
   */
  constructor () {
    this.events = new EventEmitter()
    this.favouritesElement = document.querySelector(`div[${componentQueries.favoriteToggle}]`)
    this.isUserLoggedIn = false
    this.useApi = this.favouritesElement !== null
    if (this.useApi) {
      this.favouritesElement.urls = {
        addUrl: this.favouritesElement.getAttribute(componentQueries.addUrl),
        removeUrl: this.favouritesElement.getAttribute(componentQueries.removeUrl),
        removeAllUrl: this.favouritesElement.getAttribute(componentQueries.removeAllUrl),
        getUrl: this.favouritesElement.getAttribute(componentQueries.getUrl),
        loginUrl: this.favouritesElement.getAttribute(componentQueries.loginUrl)
      }
      this._getFavouritesAsync().then(response => {
        if (!!response && !response.favouritesException && this.isUserLoggedIn) {
          this._init({ accos: response.jsonData.accoIds })

          if (response.favouritesMigrated) {
            this._deleteCookie(cookieName)
            this._setCookieMigrated()
          }

          this._updateCookie(cookieApiName)
        } else if (!!response && this.isSunwebCeLoggedIn) {
          const cookieData = this._getCookie(cookieName)
          this._init(cookieData)
        }
      })
    } else {
      let cookieData = this._getCookie(cookieName)

      if (this._isCookieMigrated()) {
        cookieData = this._getCookie(cookieApiName)

        this._deleteCookie(cookieApiName)
        this._deleteCookie(cookieMigratedName)
      }

      this._init(cookieData)
    }
  }

  _init (currentData) {
    const accos = currentData && currentData.accos
      ? currentData.accos.map(m => ({ id: m.toString() }))
      : []

    this.data = new Collection(accos)
    this.events.emit('init')
  }

  _canUseApi () {
    return this.useApi && !this.isSunwebCeLoggedIn
  }

  shouldDisplayModal () {
    return this._canUseApi() && !this.isUserLoggedIn
  }

  itemsLength () {
    return this.data?.models.length || 0
  }

  loginUrl () {
    return this.favouritesElement?.urls?.loginUrl || ''
  }

  async addItemAsync (itemId, contextItemId, publicationCode) {
    let added = false

    if (this._canUseApi()) {
      const result = await this._addFavouriteAsync(itemId, contextItemId, publicationCode)

      if (result.success) {
        this.data.addItem({ id: itemId })
        this._updateCookie(cookieApiName)
        added = true
      }
    } else {
      this.data.addItem({ id: itemId })
      this._updateCookie(cookieName)
      added = true
    }

    if (added) {
      this.events.emit('change', itemId)
    }

    return added
  }

  async removeItemAsync (itemId, contextItemId, publicationCode) {
    let removed = false
    const itemToRemove = this.data.findWhere('id', itemId)

    if (itemToRemove) {
      if (this._canUseApi()) {
        const result = await this._removeFavouriteAsync(itemId, contextItemId, publicationCode)

        if (result.success) {
          this.data.remove(itemToRemove)
          this._updateCookie(cookieApiName)
          removed = true
        }
      } else {
        this.data.remove(itemToRemove)
        this._updateCookie(cookieName)
        removed = true
      }

      if (removed) {
        this.events.emit('change', itemId)
      }
    }

    return removed
  }

  hasItem (itemId) {
    return !!this.data.findWhere('id', itemId)
  }

  _getCookie (name) {
    return storage.cookie.get(name)
  }

  _updateCookie (name) {
    storage.cookie.set(name, {
      accos: this.data.models.map(m => m.attributes.id)
    }, cookieSettings)
  }

  _deleteCookie (name) {
    storage.cookie.delete(name)
  }

  _setCookieMigrated () {
    storage.cookie.set(cookieMigratedName, true, cookieSettings)
  }

  _isCookieMigrated () {
    const favouritedMigrated = storage.cookie.get(cookieMigratedName)

    return favouritedMigrated === true
  }

  async reset (items) {
    if (this._canUseApi()) {
      await this._removeAllFavouritesAsync()
    }
    this.data.reset(items)
    this._updateCookie(cookieName)
    this.events.emit('change')
  }

  // Async Favourites
  async _getFavouritesAsync () {
    const response = await this._getFavouritesApiCallAsync(this.favouritesElement.urls.getUrl)

    if (!response || !response.jsonData) return null

    this.isSunwebCeLoggedIn = response.jsonData.isSunwebCeLoggedIn
    if (response.jsonData.isUserLoggedIn) {
      this.isUserLoggedIn = true
    } else {
      this.modalTitle = response.jsonData.modalTitle
      this.modalBodyText = response.jsonData.modalBodyText
      this.modalLoginButtonText = response.jsonData.modalLoginButtonText
      this.modalFooterText = response.jsonData.modalFooterText
      this.modalFooterLinkText = response.jsonData.modalFooterLinkText
    }

    const favouritesMigratedHeader = response.headers.get('x-productfavourites-cookiemigrated')
    const favouritesExceptionHeader = response.headers.get('x-productfavourites-exception')

    return {
      jsonData: response.jsonData,
      favouritesMigrated: /true/i.test(favouritesMigratedHeader),
      favouritesException: !!favouritesExceptionHeader
    }
  }

  async _addFavouriteAsync (accommodationId, contextItemId, publicationCode) {
    const result = await this._editFavouritesApiCallAsync(this.favouritesElement.urls.addUrl, accommodationId, contextItemId, publicationCode)
    this.isUserLoggedIn = result.isUserLoggedIn
    return result
  }

  async _removeFavouriteAsync (accommodationId, contextItemId, publicationCode) {
    const result = await this._editFavouritesApiCallAsync(this.favouritesElement.urls.removeUrl, accommodationId, contextItemId, publicationCode)
    this.isUserLoggedIn = result.isUserLoggedIn
    return result
  }

  async _removeAllFavouritesAsync () {
    const result = await this._editFavouritesApiCallAsync(this.favouritesElement.urls.removeAllUrl)
    this.isUserLoggedIn = result.isUserLoggedIn
    return result
  }

  async _editFavouritesApiCallAsync (url, accommodationId, contextItemId, publicationCode) {
    try {
      const requestUrl = getUrlFromString(url, { accommodationId, contextItemId, publicationCode })
      const result = await fetchJsonData(requestUrl, { fullReferrerOnCrossOrigin: true })

      return result
    } catch (error) {
      console.log(error)
    }
  }

  async _getFavouritesApiCallAsync (url) {
    try {
      const requestUrl = getUrlFromString(url)
      const result = await fetchJsonDataAndHeaders(requestUrl, { fullReferrerOnCrossOrigin: true })

      return {
        jsonData: result.jsonData,
        headers: result.headers
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const favorites = new Favorites()
export default favorites
