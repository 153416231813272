/**
 * The Dropdown Multiple Option contains all data needed to hydrate a Dropdown Multiple
 *
 * @typedef {Object}            DropdownData
 *
 * @property {String}           [id]                  Form element id
 * @property {String}           [placeholder]         Form element placeholder
 * @property {Boolean}          [icon]
 * @property {Object}           [attributes]          Extra attributes to be shown
 */

import { ChipTemplate } from '../chip/c-chip.template'
import { FloatingBoxTemplate } from '../floating-box/c-floating-box.template'
import { renderSelectedItemsCompact, buildChoiceListItem } from './c-dropdown_helper'
import { FloatingBoxBodyTemplate } from '../floating-box/c-floating-box__body.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { FloatingBoxFooterTemplate } from '../floating-box/c-floating-box__footer.template'
import { ChoiceListTemplate } from '../choice-list/c-choice-list.template'

export const defaultDropdownMultipleData = {
  id: '',
  placeholder: '',
  variant: 'default',
  icon: false,
  options: [],
  floatingBoxContent: ''
}

/**
  *
  * @param {options} d dropdown's data
  * @returns {String} Body content for the dropdown
  */
export const buildFloatingBoxBody = function (d) {
  let floatingBoxBody = ''
  if (d) {
    floatingBoxBody = FloatingBoxBodyTemplate(
      {
        extraClasses: '',
        attributes: {}
      },
      ChoiceListTemplate(
        {
          id: d.id,
          method: 'multiple',
          label: '',
          attributes: { 'data-c-dropdown__choice-list': '' },
          items: buildChoiceListItem(d.options, d.id)
        }
      )
    )
  }
  return floatingBoxBody
}

/**
  *
  * @param {options} d dropdown's data
  * @returns {String} Footer content for the dropdown
  */
export const buildFloatingBoxFooter = function (d) {
  let floatingBoxFooter = ''
  if (d) {
    floatingBoxFooter = FloatingBoxFooterTemplate(
      {
        extraClasses: '',
        attributes: {}
      },
      BtnTemplate({
        ...defaultButtonData,
        ...{
          text: d.cancelButtonText,
          variant: 'flat-neutral',
          attributes: { 'data-c-dropdown__action': 'cancel' }
        }
      }) + BtnTemplate({
        ...defaultButtonData,
        ...{
          text: d.okButtonText,
          attributes: { 'data-c-dropdown__action': 'submit' }
        }
      })
    )
  }
  return floatingBoxFooter
}

export const DropdownMultipleTemplate = (d) => {
  d = { ...defaultDropdownMultipleData, ...d }
  return `<div class="c-dropdown__wrapper">
      ${d.variant === 'compact' ? `<label class="c-dropdown__label e-form-label"for="${d.id}" data-c-dropdown__label>${d.label}</label>` : ''}
      ${d.icon ? `<span class="c-dropdown__icon m-icon--${d.icon}"></span>` : ''}
        <div class="c-dropdown__element" tabindex="0">
          <div class="c-dropdown__swipe" data-js-swipe data-list-class="c-dropdown__selected-item-list" data-list-item-class="c-dropdown__selected-item" data-active-class="is-active">
            <div class="c-dropdown__selected-item-list">${d.variant === 'compact'
            ? renderSelectedItemsCompact(d.options)
            : `${d.options.filter(o => o.selected).map(option => ChipTemplate(option)).join('')}`}</div>
            <button class="o-swipe-btn o-swipe-btn--left is-disabled">
              <span class="m-icon--chevron-left"></span>
            </button>
            <button class="o-swipe-btn o-swipe-btn--right is-disabled">
              <span class="m-icon--chevron-right"></span>
            </button>
            <span class="c-dropdown__placeholder">${d.placeholder}</span>
          </div>
        </div>
        <span class="c-dropdown__arrow m-icon m-icon--chevron-down"></span>
        ${d.floatingBoxContent
      ? d.floatingBoxContent
      : FloatingBoxTemplate(
        {
          opened: false,
          gradient: true,
          floatsFrom: 'xs',
          extraClasses: 'c-dropdown__floating-box',
          attributes: {
            'data-c-dropdown__floating-box': ''
          }
        },
        buildFloatingBoxBody(d) + buildFloatingBoxFooter(d)
      )}
      </div>`
}
