import { CheckboxTemplate, defaultCheckboxData } from '../checkbox/c-checkbox.template'
import { RadioButtonTemplate, defaultRadioButtonData } from '../radio-button/c-radio-button.template'
import { TooltipTemplate, defaultTooltipData } from '../../components/tooltip/c-tooltip.template'
import { SliderTemplate } from '../slider/c-slider.template'
import { encodeAttributes } from '../../../js/helpers/string'
import uid from '../../../js/core/uid'

const ChoiceListExtraClasses = {
  extraClasses: 'c-choice-list__option '
}

/**
 * The ChoiceListData contains all data needed to hydrate a ChoiceList view
 *
 * @typedef {Object}          ChoiceListData
 *
 * @property {String}         id            - The element id
 * @property {String}         variant       - The variant (default | boxed)
 * @property {String}         method        - The method (single | multiple)
 * @property {String}        [label]        - Label text
 * @property {String}        [track]        - Track for tracking (e.g. datalayers)
 * @property {CheckboxData[]|RadioButtonData[]}  [items]  - The option items
 * @property {String}        [extraClasses] - Extra classes to append to the view
 * @property {Object}        [dataset]      - Extra attributes
 * @property {Object}        [tooltip]        - data for the tooltip label
 */

export const defaultChoiceListData = {
  id: '',
  track: '',
  variant: 'default',
  method: 'single',
  label: '',
  items: [],
  extraClasses: '',
  tooltip: null,
  hasRichOptions: false,
  showInSlider: false,
  hideRequiredSymbol: false
}

export const defaultRichChoiceItemData = {
  id: '',
  name: '',
  checked: false,
  disabled: false,
  required: false,
  extraClassesForRichOption: ''
}

const retrieveExtraClassesForOption = (defaultItemData, itemData) => {
  const arrDefaultItemExtraClasses = defaultItemData.extraClasses ? defaultItemData.extraClasses.split(' ') : []
  const arrItemExtraClasses = itemData.extraClasses ? itemData.extraClasses.split(' ') : []
  const extraClassesArray = [...new Set([...arrDefaultItemExtraClasses, ...arrItemExtraClasses])].filter(e => e)

  return extraClassesArray.join(' ')
}

export const renderOption = (d, item, radioButtonData, checkBoxData) => {
  let template = ''
  const dataFromParent = {
    id: item.id ? d.id + item.id : uid(`${d.id}-`),
    name: d.name || item.name,
    disabled: d.disabled || item.disabled
  }

  if (d.method === 'single') {
    template = RadioButtonTemplate({ ...radioButtonData, ...item, ...dataFromParent, ...{ extraClasses: retrieveExtraClassesForOption(radioButtonData, item) } })
  } else {
    template = CheckboxTemplate({ ...checkBoxData, ...item, ...dataFromParent, ...{ extraClasses: retrieveExtraClassesForOption(checkBoxData, item) } })
  }

  return template
}

export const renderRichOption = (d, item, radioButtonData, checkBoxData) => {
  d = { ...defaultRichChoiceItemData, ...d }
  let richOptionExtraClassesArray = item.extraClassesForRichOption ? item.extraClassesForRichOption.split(' ') : []
  richOptionExtraClassesArray = richOptionExtraClassesArray.filter(s => s !== 'is-checked')

  const richOptionExtraClasses = [...new Set([...['c-choice-list__option-rich'], ...richOptionExtraClassesArray])].filter(e => e).join(' ')
  return `
    <div class="${richOptionExtraClasses} ${item.checked ? 'is-checked' : ''}">
      <div class="c-choice-list__option-rich-item">
        ${renderOption(d, item, radioButtonData, checkBoxData)}
      </div>
      <label class="c-choice-list-rich__label" for="${d.id + item.id}">
        <div class="c-choice-list__option-rich-item__content" data-choice-list__option-rich-item__content>
          ${item.extraRichContent}
        </div>
      </label>
    </div>
  `
}

export const getOptionItems = (d) => {
  const checkBoxData = { ...defaultCheckboxData, ...ChoiceListExtraClasses }
  const radioButtonData = { ...defaultRadioButtonData, ...ChoiceListExtraClasses }

  return d.hasRichOptions
    ? d.items.map(item => renderRichOption(d, item, radioButtonData, checkBoxData))
    : d.items.map(item => renderOption(d, item, radioButtonData, checkBoxData))
}

export const ChoiceListTemplate = (d) => {
  return `
<div
  id="${d.id}"
  class="c-choice-list
  ${d.variant ? `c-choice-list--${d.variant}` : ''}
  ${d.showInSlider ? 'c-choice-list--with-slider' : ''}
  ${d.hasRichOptions ? 'c-choice-list--rich-options' : ''}
  ${d.required ? 'is-required' : ''}
  ${d.hideRequiredSymbol ? 'c-choice-list--without-required-symbol' : ''}
  ${d.extraClasses ? d.extraClasses : ''}"
  ${d.required ? ' required' : ''}
  ${d.minlength ? ` minlength=${d.minlength}` : ''}
  data-c-choice-list__method="${d.method}"
  ${d.track ? `data-track=${d.track}` : ''}
  ${d.showInSlider ? 'data-c-choice-list__show-in-slider' : ''}
  ${d.variant ? `data-c-choice-list__variant=${d.variant}` : ''}
  data-js-component="c-choice-list"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.label
    ? `${d.tooltip ? '<div class="e-form-label__container">' : ''}
      <label class="c-choice-list__label e-form-label" for=${d.id}>${d.label}</label>
    ${d.tooltip ? `${TooltipTemplate({ ...defaultTooltipData, ...d.tooltip })} </div>` : ''}
  `
  : ''}
  <div class="c-choice-list__options">
    ${
      (d.showInSlider)
      ? ChoiceListOptionTemplateVerticalBoxed(d)
      : getOptionItems(d).join('')
    }
  </div>
  <div class="c-choice-list__messages">
  </div>
</div>
`
}

export const ChoiceListOptionTemplateVerticalBoxed = (d) => {
  return SliderTemplate({
    id: 'choice-list--slider',
    extraClasses: 'c-choice-list--boxed-slider',
    center: true,
    stretch: true,
    luminosity: 'light',
    outerNavigation: true,
    withGradient: true,
    attributes: {
      'data-loop': false,
      'data-resolve-on-intersect': false,
      'data-responsive': 'xs:1,sml:2,md:3',
      'data-gutter': '12'
    },
    items: getOptionItems(d)
  })
}
