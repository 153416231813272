/**
 * The FloatingBoxBodyData contains all data needed to hydrate the view
 *
 * @typedef {Object}          FloatingBoxBodyData
 *
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 *
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultFloatingBoxBodyData = {
  extraClasses: '',
  attributes: {}
}

export const FloatingBoxBodyTemplate = (d, content) => {
  d = { ...defaultFloatingBoxBodyData, ...d }
  return `<div class="c-floating-box__body
    ${d.extraClasses ? d.extraClasses : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  <div class="c-floating-box__body-content sp-stack-children--medium">
    ${content}
  </div>
</div>`
}
