import { getToken } from '../../../js/helpers/tokens'
import { ImgTemplate, defaultImgData } from '../img/c-img.template'

/**
 * The CollectionItemData contains all data needed to hydrate a Collection Item view
 *
 * @typedef {Object}          CollectionItemData
 *

  * @property {String}         titleTag            - Title tag
  * @property {String}         collection          - Collection
  * @property {String}         title               - Title
  * @property {String}         subTitle            - Subtitle
  * @property {String}         link                - Link
  * @property {String}         image               - Image
  * @property {String}         extraClasses        - Extra classes
 */

import { encodeAttributes } from '../../../js/helpers/string'

// Import cross-component shared tokens.
const tokensCollections = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/data/ds-tokens-shared.json`)['collections']; // eslint-disable-line
  } catch (error) {
    return {}
  }
})()

export const defaultCollectionImageData = {
  sync: true,
  resolve: 3 // intersect
}

export const defaultCollectionItemData = {
  titleTag: 'h5',
  collection: '',
  title: '',
  subTitle: '',
  link: null,
  image: {},
  extraClasses: '',
  attributes: {}
}

export const CollectionItemTemplate = d => {
  d = { ...defaultCollectionItemData, ...d }
  const collectionId = d.collection ? d.collection.id : ''
  const collectionToken = getToken('collection', collectionId, tokensCollections) || undefined

  return `
<a class="c-collection-row__item  
    ${collectionToken ? 'c-collection-row__item--collection' : ''}
    ${d.extraClasses}"
    ${d.link ? `href="${d.link.href}" target="${d.link.target}" title="${d.link.title}"` : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  <div class="c-collection-row__item-image">
    ${ImgTemplate({ ...defaultImgData, ...defaultCollectionImageData, ...d.image })}
  </div>
  <div class="c-collection-row__item-content
    ${collectionId && collectionToken ? collectionToken.colorScheme : 'cs-translucent'}">
    <${d.titleTag} class="c-collection-row__item-title">${d.title}</${d.titleTag}>
    ${d.subTitle ? `<p class="c-collection-row__item-subtitle">${d.subTitle}</p>` : ''}
    <div class="c-collection-row__item-icon">
      <span class="m-icon--chevron-right"></span>
    </div>
  </div>
</a>
`
}
