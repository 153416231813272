/**
 * The FloatingBoxData contains all data needed to hydrate the view
 *
 * @typedef {Object}          FloatingBoxData
 *
 * @property {Boolean}        opened              - True if opened
 * @property {Boolean}        gradient            - True if gradient
 * @property {String}         floatsFrom          - Breakpoint where should start floating
 * @property {String}         [minHeight]         - Minimum height
 * @property {String}         [maxHeight]         - Maximum height
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 *
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultFloatingBoxData = {
  opened: false,
  gradient: false,
  floatsFrom: 'xs',
  extraClasses: '',
  attributes: {}
}

export const FloatingBoxTemplate = (d, content) => {
  d = { ...defaultFloatingBoxData, ...d }
  return `<div class="c-floating-box
    ${d.opened ? 'in' : ''}
    ${d.gradient ? 'c-floating-box--with-gradient' : ''}
    ${d.floatsFrom ? `c-floating-box--floats-from@${d.floatsFrom}` : ''}
    ${d.extraClasses ? d.extraClasses : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}
  data-js-component="c-floating-box">
  ${content}
</div>`
}
