import Component from '../../../../js/core/component/component'
import { ChipTemplate } from '../../chip/c-chip.template'
import { registerComponent } from '../../../../js/core/component/component-directory'
import domEventsHelper from '../../../../js/document/dom-events-helper'
import { elementFromString } from '../../../../js/document/html-helper'
import { queueFilterEvent } from '../../../../js/helpers/queue-filter-event'
import registeredEvents from '../../../../js/helpers/registered-events'
import { DropdownMultipleCompactItemTemplate } from './c-dropdown__multiple__compact-item.template'

// Ensure other child component APIs are loaded on time
require('../../choice-list/main')
require('../../floating-box/main')

const componentApi = 'c-dropdown-multiple'

const attr = {
  track: 'data-track'
}

const definition = {
  name: componentApi,
  props: [
    {
      name: 'disabled',
      type: 'boolean',
      attr: '.is-disabled',
      defaultValue: false
    },
    {
      name: 'options',
      type: 'collection'
    },
    {
      name: 'optionsData',
      type: 'collection'
    },
    {
      name: 'variant',
      type: 'string',
      attr: 'data-variant',
      defaultValue: 'default'
    },
    {
      name: 'saveOnDomClick',
      type: 'boolean',
      attr: 'data-save-dom-click',
      defaultValue: false
    }
  ]
}

const componentQueries = {
  focusElement: '.c-dropdown__element',
  optionsList: '.c-dropdown__selected-item-list',
  chipRemove: '[data-c-chip__action="remove"]',
  floatingBox: '[data-c-dropdown__floating-box]',
  choiceList: '[data-c-dropdown__choice-list]',
  cancel: '[data-c-dropdown__action="cancel"]',
  submit: '[data-c-dropdown__action="submit"]',
  label: '[data-c-dropdown__label]',
  arrow: '.c-dropdown__arrow',
  compact: 'compact',
  hasValues: 'has-values'
}

const defaults = {
  minHeight: '260px',
  maxHeight: '400px'
}

const changeEvent = new window.CustomEvent('change', { bubbles: true, cancelable: false })

/**
 * DropdownMultiple content
 *
 */
export default class DropdownMultiple extends Component {
  /**
   * Creates a new dropdown behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.opened = false

    // Access to floatingBox element and it's API
    this.floatingBox = this.element.querySelector(componentQueries.floatingBox)
    this.floatingBoxApi = this.floatingBox['c-floating-box']
    this.floatingBoxApi.setProp('minHeight', defaults.minHeight, { silent: true })
    this.floatingBoxApi.setProp('maxHeight', defaults.maxHeight, { silent: true })

    this.arrow = this.element.querySelector(componentQueries.arrow)

    // Access to ChoiceList element and it's API
    this.xoiceList = this.element.querySelector(componentQueries.choiceList)
    this.xoiceListApi = this.xoiceList['c-choice-list']

    // Populate missing props
    this.props.options = [...this.getOptions()]
    this.props.optionsData = [...this.getOptionsData()]
    this.selectedValues = [...this.xoiceListApi.getSelectedValues()]

    // Find for child elements and bind some DOM events associated
    this.focusElement = this.element.querySelector(componentQueries.focusElement)
    this.optionsListElement = this.element.querySelector(componentQueries.optionsList)
    this.cancelElement = this.element.querySelector(componentQueries.cancel)
    this.submitElement = this.element.querySelector(componentQueries.submit)
    domEventsHelper.attachEvents([
      [this.element, { keydown: (ev) => this.onKeyDown(ev) }],
      [this.focusElement, { focus: (ev) => this.onFocus(ev) }],
      [this.optionsListElement, { mousedown: (ev) => this.onClickChipList(ev) }],
      [this.cancelElement, { click: (ev) => this.onCancel(ev) }],
      [this.submitElement, { click: (ev) => this.onSubmit(ev) }]
    ], definition.name)

    this.isCompact = this.element.getAttribute('data-variant') === componentQueries.compact

    // Bind ChoiceList events
    this.xoiceListApi.events.on('changeOptions', options => {
      this.props.options = JSON.parse(JSON.stringify(options))
      this.props.optionsData = [...this.getOptionsData()]
      this.selectedValues = [...this.xoiceListApi.getSelectedValues()]
      this._updateOptions()
      // IE/Edge bug does not update the :empty state on time, by toggling a fake class forces a repaint solving the bug that overlaps placeholder on the filters selected
      this.element.classList.toggle('weird')
      this.selectedValues.length !== 0 ? this.element.classList.add(componentQueries.hasValues) : this.element.classList.remove(componentQueries.hasValues)
      this._recoverFocus()
      this.events.emit('changeOptions', this.props.options)
    })
    // Bind chevron event

    this.arrow.addEventListener('click', this.toggleFloatingBox.bind(this))

    element[this.name].getSelectedValues = (this.getSelectedValues).bind(this)
    element[this.name].getOkButtonLabelText = (this.getOkButtonLabelText).bind(this)
    element[this.name].getOkButton = (this.getOkButton).bind(this)
    element[this.name].getCancelButtonLabelText = (this.getCancelButtonLabelText).bind(this)
    element[this.name].getCancelButton = (this.getCancelButton).bind(this)
    element[this.name].getLabel = (this.getLabel).bind(this)
    element[this.name].open = (this.open).bind(this)

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
   * Opens the Dropdown
   *
   * - Init the fallback values to handle cancel events
   * - Opens the FloatingBox
   * - Bind documentClick event
   * - Set focus on first option
   * - Emit OPEN event
   *
   * @param {Object} options - Options object
   * @param {Boolean} [options.silent] - If true, does not fire events
   *
   * @return {DropdownMultiple} self instance
   */
  open (options = {}) {
    if (this.opened) return this
    this.focusElement.classList.add('is-focused')
    this.fallbackValues = [...this.selectedValues]
    this.floatingBoxApi.setProp('opened', true)
    this._openedEvents = [
      [document, { mousedown: (ev) => this.onDocumentClick(ev) }]
    ]
    domEventsHelper.attachEvents(this._openedEvents, definition.name)
    // Move the focus to first element
    this.moveFocusToFirstElement()
    this.opened = true
    this.updateChevron()
    if (!options.silent) this.events.emit('open')
    return this
  }

  moveFocusToFirstElement () {
    this.focusableOptions = this.xoiceList.querySelectorAll('input')
    if (!this.focusableOptions.length) return
    this.focusableOptions[0].focus()
    this.lastFocusedElement = this.focusableOptions[0]
    this.lastFocusedIndex = 0
  }

  /**
   * Closes the Dropdown
   *
   * - UnBind documentClick event
   * - Closes the FloatingBox
   * - Set focus on first option
   * - If SAVE is TRUE:
   *   - Emit SUBMIT event
   * - If SAVE is FALSE:
   *   - Restore fallback options
   * - Emit CLOSE event
   *
   * @param {Object} options - Options object
   * @param {Boolean} [options.silent] - If true, does not fire events
   * @param {Boolean} [options.save] - If true, save the changes
   *
   * @return {DropdownMultiple} self instance
   */
  close (options = {}) {
    if (!this.opened) return this
    domEventsHelper.detachEvents(this._openedEvents, definition.name)
    this.focusElement.classList.remove('is-focused')
    this.floatingBoxApi.setProp('opened', false)
    let shouldEmit = false
    if (!options.save) {
      const newOptions = JSON.parse(JSON.stringify(this.props.options))
        .map(option => {
          option.checked = this.fallbackValues.includes(option.value)
          return option
        })
      this.xoiceListApi.setProp('options', newOptions)
    } else {
      shouldEmit = options.silent
        ? false
        : (JSON.stringify(this.fallbackValues) !== JSON.stringify(this.selectedValues))
    }
    this.fallbackValues = undefined
    this.focusableOptions = undefined
    this.lastFocusedElement = undefined
    this.lastFocusedIndex = undefined
    this.opened = false
    this.updateChevron()
    if (shouldEmit) {
      this.events.emit('submit', this.props.options)
    }
    if (!options.silent) this.events.emit('close')
    return this
  }

  toggleFloatingBox () {
    if (this.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  updateChevron () {
    if (this.opened) {
      this.arrow.classList.remove('m-icon--chevron-down')
      this.arrow.classList.add('m-icon--chevron-up')
    } else {
      this.arrow.classList.remove('m-icon--chevron-up')
      this.arrow.classList.add('m-icon--chevron-down')
    }
  }

  /**
   * Get the whole array of options from ChoiceList
   */
  getOptions () {
    return this.xoiceListApi ? this.xoiceListApi.props.options : []
  }

  /**
   * Set the array of options to Dropdown and ChoiceList
   *
   * @param {Object[]} items - Items to be set
   * @param {Object} options - Options object
   * @param {Boolean}[options.silent]- If true, does not fire events
   *
   */
  setOptions (items, options) {
    this.xoiceListApi.setProp('options', items, options)
  }

  /**
   * Get optionsData from props
   *
   * @returns {OptionsData[]}
   */
  getOptionsData () {
    const getOptionsDataFromOptions = (options) => {
      const optionsData = []
      options
        .forEach(option => {
          optionsData.push({
            value: option.value,
            text: option.text
          })
          if (option.items) optionsData.push(...getOptionsDataFromOptions(option.items))
        })
      return optionsData
    }
    return getOptionsDataFromOptions(this.props.options)
  }

  /**
   * Set optionsData collection, expecting a value and text for every one
   *
   * @param {OptionsData[]} optionsData
   * @param {Object} options - Options object
   * @param {Boolean}[options.silent]- If true, does not fire events
   * @param {Boolean}[options.forceUpdate]- If true, also updates the items
   */
  setOptionsData (optionsData, options) {
    this._updateOptions()
  }

  /**
   * Get selected values
   *
   * @return {String[]} values
   */
  getSelectedValues () {
    return this.selectedValues
  }

  /**
   * Get 'OK' button label text
   *
   * @return {String} Ok button label text
   */
  getOkButtonLabelText () {
    return this.submitElement['c-btn'].getText()
  }

  /**
   * Get 'OK' button
   *
   * @return {String} Ok button
   */
  getOkButton () {
    return this.submitElement
  }

  /**
   * Get 'Cancel' button label text
   *
   * @return {String} Cancel button label text
   */
  getCancelButtonLabelText () {
    return this.cancelElement['c-btn'].getText()
  }

  /**
   * Get 'Cancel' button
   *
   * @return {String} Cancel button
   */
  getCancelButton () {
    return this.cancelElement
  }

  /**
   * Get label text
   *
   * @return {String}  label text
   */
  getLabel () {
    return this.element.querySelector(componentQueries.label).innerHTML
  }

  /**
   * Handle clicks on chipsElement, by mousedown to handle before other events like blur
   */
  onClickChipList (ev) {
    if (!ev.target.matches(componentQueries.chipRemove)) {
      this.onFocus()
      return
    }
    const matchValue = ev.target.parentElement.dataset.value
    const matchInput = this.xoiceList.querySelector(`input[value='${matchValue}']`)
    if (!matchInput) return
    matchInput.checked = false
    matchInput.dispatchEvent(changeEvent)

    // Removing a chip must only trigger a submit event if the dropdown is closed
    if (!this.opened) {
      this.events.emit('submit', this.props.options)
    }
    ev.preventDefault()
    ev.stopPropagation()
  }

  /**
   * Handled by keyDOWN on element
   *
   * - For UP and DOWN arrow keys
   *   - Focus next/prev input
   *   - Ensure result visibility
   *
   * - For ENTER and TAB keys
   *   - Submit tempSelection
   *
   * - For ESC key
   *   - Cancel tempSelection
   */
  onKeyDown (ev) {
    // Ignore event if the FloatingBox is not opened
    if (!this.floatingBoxApi.props.opened) return

    const key = ev.keyCode

    // Change keys: Down (40), Up (38)
    if (key === 40 || key === 38) {
      const currentElementIndex = [...this.focusableOptions].includes(document.activeElement)
        ? [...this.focusableOptions].indexOf(document.activeElement)
        : this.lastFocusedIndex
      const nextElement = key === 40
        ? this.focusableOptions.length > currentElementIndex + 1
          ? this.focusableOptions[currentElementIndex + 1]
          : this.focusableOptions[currentElementIndex]
        : (currentElementIndex - 1) >= 0
            ? this.focusableOptions[currentElementIndex - 1]
            : this.focusableOptions[currentElementIndex]
      if (nextElement !== this.lastFocusedElement) {
        const newIndex = [...this.focusableOptions].indexOf(nextElement)
        this.focusableOptions[newIndex].focus()
        this.lastFocusedElement = this.focusableOptions[newIndex]
        this.lastFocusedIndex = newIndex
      }
      ev.preventDefault()
    }

    // Submit keys: Enter (13), Tab (9)
    if (key === 13 || key === 9) { this.close({ save: true }) }

    // Discard keys: Esc (27)
    if (key === 27) { this.close() }
  }

  /**
   * Handle cancel events
   */
  onCancel () {
    this.close()
  }

  /**
   * Handle element focus events
   */
  onFocus () {
    this.open()
  }

  /**
   * Handle submit events
   */
  onSubmit () {
    this.close({ save: true })
  }

  /**
   * Handle document click event
   */
  onDocumentClick (ev) {
    const shouldSave = this.getProp('saveOnDomClick')
    const shouldEmit = (JSON.stringify(this.fallbackValues) !== JSON.stringify(this.selectedValues))
    queueFilterEvent(
      ev,
      this.element,
      () => this.close({ silent: !shouldEmit, save: shouldSave }),
      shouldEmit
    )

    // Handle input clicks to focus there
    if (ev.target.closest('.c-choice-list__option')) {
      const targetClicked = ev.target.closest('.c-choice-list__option').querySelector('.c-checkbox__input')
      if ([...this.focusableOptions].includes(targetClicked)) {
        const newIndex = [...this.focusableOptions].indexOf(targetClicked)
        this.focusableOptions[newIndex].focus()
        this.lastFocusedElement = this.focusableOptions[newIndex]
        this.lastFocusedIndex = newIndex
      }
    }
  }

  /**
   * Recover focus after a change on ChoiceList
   *
   * @return {DropdownMultiple} self instance
   */
  _recoverFocus () {
    if (!this.floatingBoxApi.props.opened) return this
    this.focusableOptions = this.xoiceList.querySelectorAll('input')
    if (this.focusableOptions[this.lastFocusedIndex]) {
      this.focusableOptions[this.lastFocusedIndex].focus()
      this.lastFocusedElement = this.focusableOptions[this.lastFocusedIndex]
    }
    return this
  }

  /**
   * Flush all item elements for this component
   *
   * @return {DropdownMultiple} self instance
   */
  _flushOptionsElements () {
    while (this.optionsListElement.firstChild) {
      this.optionsListElement.removeChild(this.optionsListElement.firstChild)
    }
    return this
  }

  /**
   * Renew all item elements for this component
   *
   * @param {Object[]} options - Options from where to update items
   *
   * @return {DropdownMultiple} self instance
   */
  _updateOptions (options = this.props.options) {
    this._flushOptionsElements()
    if (this.isCompact) {
      this.xoiceListApi.getReducedSelectedValuesFromOptions()
        .forEach(value => {
          const newItem = this._newItemElement(value)
          if (newItem) { this.optionsListElement.appendChild(newItem) }
        })
    } else {
      this.xoiceListApi.getReducedSelectedValuesFromOptions()
        .forEach(value => {
          const newChip = this._newChipElement(value)
          if (newChip) { this.optionsListElement.appendChild(newChip) }
        })
    }
    return this
  }

  /**
   * Creates a chip element from option data
   *
   * @param {String} value - The associated value to create the view with
   *
   * @return {HTMLElement}
   *
   */
  _newChipElement (value) {
    const chipData = this.props.optionsData
      .find(chip => chip.value === value)
    if (!chipData) {
      return undefined
    }
    const newChipData = {
      value: chipData.value,
      text: chipData.text
    }
    return elementFromString(ChipTemplate(newChipData))
  }

  /**
   * Creates a item element from option data when multiple option is compact
   *
   * @param {String} value - The associated value to create the view with
   *
   * @return {HTMLElement}
   *
   */
  _newItemElement (value) {
    const ItemData = this.props.optionsData
      .find(chip => chip.value === value)
    if (!ItemData) {
      return undefined
    }
    const newItemData = {
      value: ItemData.value,
      text: ItemData.text
    }
    return elementFromString(DropdownMultipleCompactItemTemplate(newItemData))
  }
}

registerComponent(DropdownMultiple, definition.name, definition)
