const defaultOptions = {
  siteKey: '6LeAy1cUAAAAALiBq30Rt-gcDYnZGCgEWq-anpg-'
}

/**
 * An engine to include reCAPTCHA in c-form components that are setup to have one.
 *  Types supported:
 *    * reCAPTCHA v2 Invisible. Validates requests in the background.
 *        It attaches to the button submit of the c-form and *needs* post-processing of event 'recaptcha-form-submit'.
 */
export default class RecaptchaEngine {
  /**
   * Creates a new recaptcha in the form provided as element with the options provided.
   * @constructor
   * @param {Element} element - The targeted HTML form element
   * @param {Object} options - The recaptcha options
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = Object.assign({}, defaultOptions, options)
    this.selectors = {}
    this._init()
  }

  /**
 * Creates a recaptchas in all forms that have the attribute data-c-form__recaptcha to true.
 * @returns {RecaptchaEngine[]} The created instances
 */
  static createRecaptchasOnDocument () {
    const recaptchaInstances = []
    const currentComponents = RecaptchaEngine.getRecaptchasOnDocument()
    for (let i = 0; i < currentComponents.length; i++) {
      const current = currentComponents[i]
      const options = RecaptchaEngine.getOptions(current)
      const instance = RecaptchaEngine.createRecaptchaOnForm(current, options)

      recaptchaInstances.push(instance)
    }

    return recaptchaInstances
  }

  static getRecaptchasOnDocument () {
    return window.document.querySelectorAll('.c-form[data-c-form__recaptcha]')
  }

  /**
   * Creates a new RecaptchaEngine in the form provided as element.
   * @param {Element} element - The targeted HTML form element
   * @param {Object} options - The options of the recaptcha
   * @returns {RecaptchaEngine} The created instance
   */
  static createRecaptchaOnForm (element, options) {
    return new RecaptchaEngine(element, options)
  }

  /**
   * Resolves the recaptcha options for the element provided.
   * @param {Element} element - the element for which options will be resolved.
   */
  static getOptions (element) {
    const options = defaultOptions
    const siteKey = element.getAttribute('data-c-form__recaptcha-site-key')
    if (siteKey && siteKey !== '') {
      options.siteKey = siteKey
    }
    return options
  }

  /**
   * Indicates if grecaptcha script is available or not.
   */
  static isGRecaptchaAvailable () {
    return typeof window.grecaptcha !== 'undefined' && window.grecaptcha
  }

  /**
   * Injects the recaptcha API script into the document.
   */
  static injectRecaptchaScript (callbackName) {
    const currentComponents = RecaptchaEngine.getRecaptchasOnDocument()
    if (currentComponents.length > 0) {
      // resolve the src
      let src = 'https://www.google.com/recaptcha/api.js?onload=' + callbackName + '&render=explicit'
      const language = document.documentElement.getAttribute('lang')
      if (language && language !== '') {
        src = src + '&hl=' + language
      }

      // Inject the script
      const tag = document.createElement('script')
      document.head.appendChild(tag)
      tag.src = src
    }
  }

  /**
   * Initialization of the RecaptchaEngine, creating the recaptcha inside the form with the options provided.
   * @returns {RecaptchaEngine} self instance
   */
  _init () {
    this.selectors.submitButton = this.element.querySelector('[type="submit"]')
    this.element.addEventListener('form-validation-failed', this._reset.bind(this))
    this._initRecaptcha()
  }

  _initRecaptcha () {
    if (RecaptchaEngine.isGRecaptchaAvailable()) {
      this.recaptchaId = window.grecaptcha.render(this.selectors.submitButton, {
        sitekey: this.options.siteKey,
        size: 'invisible',
        callback: function (token) {
          this._submitForm()
        }.bind(this)
      })
    }
    return this
  }

  _submitForm () {
    this.element.dispatchEvent(new window.CustomEvent('recaptcha-form-submit'))
  }

  _reset () {
    if (RecaptchaEngine.isGRecaptchaAvailable()) {
      window.grecaptcha.reset(this.recaptchaId)
    }
  }
}
