import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import {
  areDatesInSameMonth,
  areDatesInSameYear,
  dateToString,
  isInvalidDate,
  isValidDateString,
  createDate,
  noTimeZoneDate
} from '../../../js/helpers/dates'
import { range } from '../../../js/utils'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { fromCamelCase } from '../../../js/helpers/string'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { DateSelectorMessagesTemplate } from './c-date-selector__messages.template'
const dateLocales = register(`window.sundio.i18n.${language}.dates`)
const componentLocales = register(`window.sundio.i18n.${language}.dateSelector`)

// Ensure other child component APIs are loaded on time
require('../dropdown/main')

const classNames = { dropdownElement: 'c-date-selector__' }
const monthNames = {
  default: dateLocales.months,
  short: dateLocales.months_short
}

const today = new Date()

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {

  name: 'c-date-selector',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */
  props: [
    {
      name: 'date',
      type: 'string',
      attr: 'data-c-date-selector__date'
    }, {
      name: 'rawDate',
      type: 'string'
    }, {
      name: 'validations',
      type: 'array',
      attr: 'data-c-date-selector__validations',
      defaultValue: ['isReal', 'isInBounds']
    }, {
      name: 'minDate',
      type: 'string',
      attr: 'data-c-date-selector__min-date',
      defaultValue: dateToString(new Date(today.getFullYear() - 100, today.getMonth(), today.getDate()))
    }, {
      name: 'maxDate',
      type: 'string',
      attr: 'data-c-date-selector__max-date',
      defaultValue: dateToString(today)
    }, {
      name: 'monthType',
      type: 'string',
      attr: 'data-c-date-selector__month-type',
      defaultValue: 'default',
      allowedValues: [
        'default',
        'short'
      ]
    },
    {
      name: 'disabled',
      type: 'boolean',
      attr: '.is-disabled',
      defaultValue: false
    },
    {
      name: 'state',
      type: 'string',
      attr: '.has-',
      allowedValues: [
        '',
        'error'
      ],
      defaultValue: ''
    }
  ]
}

/**
 * Dropdown content
 *
 */
export default class DateSelector extends Component {
  /**
   * Creates a new date selector behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.dateConfig = {}
    this.silentErrors = true
    this._init()

    this.element[definition.name].validate = (this.validate).bind(this)
    this.element[definition.name].getShownDate = (this.getShownDate).bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)

    this.validationErrors = {
      isReal: this.element.getAttribute('data-message-invalid'),
      isInBounds: this.element.getAttribute('data-message-out-of-bounds') ? this.element.getAttribute('data-message-out-of-bounds') : dateLocales.isOutOfBounds,
      isValid: this.element.getAttribute('data-message-required')
    }

    this._attachMonthEventListeners()
  }

  /**
   * Checks first if date is valid and within min-max boundaries
   *
   * @param {string} dateString - Date in format 'YYYY-MM-DD'
   */
  checkDate (dateString) {
    const property = this._getPropInstance('date')
    if (!property.isAllowedValue.bind(property)(dateString)) { return false }
    const isNullable = dateString == null || dateString === ''
    const { isValid, isInBounds } = this._validateAll(dateString)
    if (!isNullable) {
      if (!isValid) {
        console.warn('The date is invalid')
      } else if (!isInBounds) { console.warn('The date cannot be out of min-max date boundaries') }
    }
    return isNullable || (isValid && isInBounds)
  }

  /**
   * Set date for the date-selector component
   *
   * @param {DateString|Date} dateString - Following built-in new Date(dateString) behaviour
   * @param {Object} options - Options object
   * @param {Boolean} options.justCheck - If true, does not set the dropdowns (case when the user sets it)
   */
  setDate (dateString, options = {}) {
    const date = dateString ? createDate(dateString) : noTimeZoneDate('') // Creates invalid date to set dropdowns to blank
    this._setDatePropAndAttribute('date', this._parseDate(date))
    this.setProp('rawDate', dateString)

    if (!options.justCheck) {
      const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()]
      const dateDropdowns = this._getDateDropdowns()
      const valueToStringOrEmpty = (value) => isNaN(value) ? '' : value.toString()
      dateDropdowns.day.setProp('value', valueToStringOrEmpty(day), { silent: true })
      dateDropdowns.month.setProp('value', valueToStringOrEmpty(month), { silent: true })
      dateDropdowns.year.setProp('value', valueToStringOrEmpty(year), { silent: true })
      this._setValidationState()
    }
  }

  /**
   * Set disabled property for the dropdowns component
   *
   * @param {Boolean} isDisabled - If true, the dropdowns will be disabled
   */

  setDisabled (isDisabled = false) {
    this.props.disabled = isDisabled
    const dateDropdowns = this._getDateDropdowns()
    dateDropdowns.day.setProp('disabled', isDisabled)
    dateDropdowns.month.setProp('disabled', isDisabled)
    dateDropdowns.year.setProp('disabled', isDisabled)
  }

  /**
   * Set minimum date for the date-selector component
   *
   * @param {string|Date} dateString - if incorrect, minimum computable date
   */
  setMinDate (dateString = this.props.minDate) {
    this._setDateConfig('minDate', dateString)
  }

  /**
   * Set maximum date for the date-selector component
   *
   * @param {string|Date} dateString - if incorrect, today's date
   */
  setMaxDate (dateString = this.props.maxDate) {
    this._setDateConfig('maxDate', dateString)
  }

  /**
   * Set month type (short or long month name)
   *
   * @param {string} opc - short or default
   */
  setMonthType (opc) {
    this._setDatePropAndAttribute('monthType', opc)
    const dateDropdowns = this._getDateDropdowns()
    const options = [...dateDropdowns.month.getProp('options')]
    options.forEach((monthItem) => (monthItem.text = (monthNames[opc][monthItem.value] || monthItem.text)))
    dateDropdowns.month.setProp('options', options)
  }

  enableErrors () {
    this.silentErrors = false
  }

  /**
   * Validates the current date, adding error validation if date is invalid
   *
   * @param {Object} validateOnly - If true, we only validate
   * @return {boolean} - true if Date is valid
   */

  validate (validateOnly = false) {
    const validations = this._validateAll()
    const isRequired = this.element.hasAttribute('required')
    const isEmpty = this._fullDateIsEmpty()
    const isValidDate = (!isRequired && isEmpty) || Object.values(validations).every((item) => item)
    let messages = []
    let messageTypes = []
    if ((!validateOnly && !this.silentErrors) || this.isErrorState) {
      this._setValidationState(isValidDate ? '' : 'error')
      messages = this._setValidationErrors(validations)
      messageTypes = this._getValidationMessageTypes(validations)
    }

    const dateDropdowns = this._getDateDropdowns()
    return {
      isValid: isValidDate,
      errorMessages: messages,
      errorTypes: messageTypes,
      fieldName: dateDropdowns.day.element.querySelector('select').name + '-' +
        dateDropdowns.month.element.querySelector('select').name + '-' +
        dateDropdowns.year.element.querySelector('select').name
    }
  }

  /**
   * Returns the current date shown in date selector (even if date is not within boundaries)
   *
   * @return {DateString}
   */
  getShownDate () {
    return dateToString(createDate(this._getFullDateString()))
  }

  /**
   * Check if date is valid, within min-max boundaries and real date (Unreal date example: 31/02/2010)
   *
   * @param {DateString} [dateString] - if not specified, we take the component's date
   *
   * @return {Object} - with the different validations
   */
  _validateAll (dateString = this._getFullDateString()) {
    const date = noTimeZoneDate(dateString || '')
    const validations = this.getProp('validations').reduce((obj, value) => ({ ...obj, [value]: true }), {})
    const isRequired = this.element.hasAttribute('required')
    const isEmpty = this._fullDateIsEmpty()
    return {
      isValid: (!isRequired && isEmpty) || !isInvalidDate(date),
      isInBounds: (!isRequired && isEmpty) || !validations.isInBounds || ((date >= this.dateConfig.minDate) && (date <= this.dateConfig.maxDate)),
      isReal: (!isRequired && isEmpty) || !validations.isReal || isValidDateString(dateString)
    }
  }

  _init () {
    this._normalizeDates()
    this._setListeners()
    if (this.props.date) {
      const date = this.props.date
      this.props.date = null
      this.setProp('date', date)
    }
  }

  _parseDate (date) {
    return (date instanceof Date) ? dateToString(date) : (date || '')
  }

  _setValidationErrors (validations) {
    let validationType = Object.entries(validations).find(([type, isValid]) => !isValid)
    validationType = validationType && validationType[0]
    const errorElement = this._getErrorElement()
    errorElement.innerHTML = ''
    const messages = []
    if (validationType !== undefined) {
      const errorMessage = this._getValidationMessages(validationType)
      if (errorMessage) {
        if (this.errorElement !== null && this.errorElement !== undefined) {
          this.errorElement.innerHTML = DateSelectorMessagesTemplate(errorMessage)
        }
      }
    }
    return messages
  }

  _getValidationMessages (validationType) {
    const messages = []
    if (validationType === 'isReal' && this.validationErrors?.isReal) {
      messages.push(this.validationErrors.isReal)
    }
    if (validationType === 'isInBounds' && this.validationErrors?.isInBounds) {
      messages.push(this.validationErrors.isInBounds)
    }
    if (validationType === 'isValid' && this.validationErrors?.isValid) {
      messages.push(this.validationErrors.isValid)
    }
    return messages
  }

  _getValidationMessageTypes (validations) {
    const messageTypes = []
    let validationType = Object.entries(validations).find(([type, isValid]) => !isValid)
    validationType = validationType && validationType[0]
    if (validationType) {
      messageTypes.push(validationType)
    }
    return messageTypes
  }

  _setDatePropAndAttribute (propName, value) {
    this.props[propName] = value
    this.element.setAttribute(`data-${classNames.dropdownElement}${fromCamelCase(propName)}`, value)
  }

  _setDateConfig (opc, dateString) {
    const dateTemp = noTimeZoneDate(dateString)
    // If invalid date, we set min/max date to its minimun/maximun, respectively
    const date = (dateString == null || isInvalidDate(dateTemp))
      ? noTimeZoneDate(opc === 'minDate' ? 0 : undefined)
      : dateTemp

    this._checkDates({ ...this.dateConfig, [opc]: date }, opc)
    this.dateConfig[opc] = date
    this._setDatePropAndAttribute(opc, this._parseDate(date))
    this._setDropdownPlaceholders()
    this._populateDropdowns()
  }

  _getFullDateString () {
    const dateDropdowns = this._getDateDropdowns()
    const monthDropdownValue = dateDropdowns.month.getProp('value')
    const incrementNumberForRealMonth = 1
    const [day, month, year] = [dateDropdowns.day.getProp('value'),
      monthDropdownValue ? (Number(monthDropdownValue) + incrementNumberForRealMonth) : '',
      dateDropdowns.year.getProp('value')]

    const isNumberNotNull = (value) => (value === 0 || value)
    return (isNumberNotNull(day) && isNumberNotNull(month) && isNumberNotNull(year)) ? `${year}-${month}-${day}` : null
  }

  _fullDateIsEmpty () {
    const dateDropdowns = this._getDateDropdowns()
    const day = dateDropdowns.day.getProp('value')
    const month = dateDropdowns.month.getProp('value')
    const year = dateDropdowns.year.getProp('value')
    return day === '' && month === '' && year === ''
  }

  _getDateDropdowns () {
    this.dateDropdowns = this.dateDropdowns ||
      Array.from(this.element.querySelectorAll(`[data-${classNames.dropdownElement}type]`))
        .map((dropdownElement) => [dropdownElement.getAttribute(`data-${classNames.dropdownElement}type`), dropdownElement])
        .reduce((obj, [type, dropdownElement]) => ({ ...obj, [type]: dropdownElement['c-dropdown'] }), {})
    return this.dateDropdowns
  }

  _getErrorElement () {
    this.errorElement = this.errorElement || this.element.querySelector('.c-date-selector__messages')
    return this.errorElement
  }

  _normalizeDates () {
    this.setMinDate()
    this.setMaxDate()
  }

  _checkDates (config = this.dateConfig, opc) {
    const { minDate, maxDate, date } = config
    if (minDate && maxDate && (minDate > maxDate)) {
      const dateToChange = (opc === 'maxDate') ? 'minDate' : 'maxDate'
      console.warn(`The minDate cannot be greater than the maxDate, setting ${dateToChange} to ${opc}`)
      this.setProp(dateToChange, this.getProp(opc))
    } else if (date && ((minDate && date < minDate) || (maxDate && date > maxDate))) {
      console.warn(`The date cannot be out of min-max date boundaries, setting date to ${opc}`)
    }
  }

  _setListeners () {
    Object.values(this._getDateDropdowns())
      .forEach((dropdownComponent) => dropdownComponent.events.on('propChanged', (changes) => {
        if (changes.name === 'value') {
          const fullDateString = this._getFullDateString()
          const isValid = this.validate().isValid
          this.setProp('date', isValid ? fullDateString : '', { justCheck: true })
        }
      }))
  }

  _setValidationState (stateValue) {
    this.isErrorState = !!stateValue
    this.setProp('state', stateValue || '')
  }

  _populateDropdowns () {
    const { minDate, maxDate } = this.dateConfig

    if (minDate && maxDate) {
      let days = range(31)
      let months = range(0, 11)
      const years = range(minDate.getFullYear(), maxDate.getFullYear()).reverse()
      if (areDatesInSameYear(minDate, maxDate)) {
        months = range(minDate.getMonth(), maxDate.getMonth())
        if (areDatesInSameMonth(minDate, maxDate)) { days = range(minDate.getDate(), maxDate.getDate()) }
      }
      const defaultEmptyOption = { text: '', value: '', default: true, disabled: true }
      const dateDropdowns = this._getDateDropdowns()
      dateDropdowns.day.setProp('options', [defaultEmptyOption, ...days.map((value) => ({ value: value.toString(), text: value }))])
      dateDropdowns.month.setProp('options', [defaultEmptyOption, ...months.map((value) => ({ value: value.toString(), text: monthNames[this.getProp('monthType')][value] }))])
      dateDropdowns.year.setProp('options', [defaultEmptyOption, ...years.map((value) => ({ value: value.toString(), text: value }))])
    }
  }

  _setDropdownPlaceholders () {
    const dateDropdowns = this._getDateDropdowns()
    const [dayPlaceholder, monthPlaceholder, yearPlaceholder] = [
      dateDropdowns.day.getProp('placeholder'),
      dateDropdowns.month.getProp('placeholder'),
      dateDropdowns.year.getProp('placeholder')
    ]

    dayPlaceholder || dateDropdowns.day.setProp('placeholder', componentLocales.dayPlaceholder)
    monthPlaceholder || dateDropdowns.month.setProp('placeholder', componentLocales.monthPlaceholder)
    yearPlaceholder || dateDropdowns.year.setProp('placeholder', componentLocales.yearPlaceholder)
  }

  _attachMonthEventListeners () {
    this.buffer = []
    this.lastKeyTime = Date.now()

    const monthDropdown = this._getDateDropdowns().month

    domEventsHelper.attachEvents([
      [monthDropdown.element, { keydown: (ev) => this.onKeyDown(ev, monthDropdown) }]
    ], definition.name)
  }

  onKeyDown (ev, monthDropdown) {
    const charList = '0123456789'
    const key = ev.key.toLowerCase()

    // we are only interested in numeric keys
    if (charList.indexOf(key) === -1) return

    const currentTime = Date.now()
    if (currentTime - this.lastKeyTime > 1000) {
      this.buffer = []
    }

    this.buffer.push(key)
    this.lastKeyTime = currentTime
    const decrementNumberForMonth = 1
    const valueForMonthOption = Number(this.buffer.join('')) > 0 ? (Number(this.buffer.join('')) - decrementNumberForMonth).toString() : Number(0).toString()

    const optionCandidate = this.buffer.join('') && valueForMonthOption >= 0 ? monthDropdown.element.querySelector(`[value="${valueForMonthOption}"]`) : monthDropdown.element.firstChild
    if (optionCandidate) {
      monthDropdown.setProp('value', valueForMonthOption)
    }
    ev.stopPropagation()
    ev.preventDefault()
  }
}

registerComponent(DateSelector, definition.name, definition)
