/**
 * The FloatingBoxFooterData contains all data needed to hydrate the view
 *
 * @typedef {Object}          FloatingBoxFooterData
 *
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 *
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultFloatingBoxFooterData = {
  extraClasses: '',
  attributes: {}
}

export const FloatingBoxFooterTemplate = (d, content) => {
  d = { ...defaultFloatingBoxFooterData, ...d }
  return `<div class="c-floating-box__footer
    ${d.extraClasses ? d.extraClasses : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${content}
</div>`
}
