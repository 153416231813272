/**
 * The DateSelectorMessagesTemplate contains all the data needed to hydrate a date selector messages component
 *
 * @typedef {Object}      defaultDateSelectorMessagesData
 *
 * @property {String}     extraClasses      - Extra classes if needed
 */

export const defaultDateSelectorMessagesData = {
  extraClasses: ''
}

export const DateSelectorMessagesTemplate = d => {
  return d
    ? d.map(message => `
            <div class="c-date-selector__message">
              <i class="m-icon m-icon--warning-circle"></i>
              <span>${message}</span>
            </div>`
    ).join('')
    : ''
}
