/**
 * The CollapseData contains all data needed to hydrate a Collapse selector view
 *
 * @typedef {Object}          CollapseData
 *
 * @property {String}         id                  - Collapse's id
 * @property {String}         html                - HTML snippet
 * @property {String}         extraClasses
 * @property {Number}         maxItems            - Number of items to be visible when collapsed
 * @property {Boolean}        opened              - If true, content is expanded
 * @property {Boolean}        childrenWrapper
 * @property {Boolean}        disabled
 * @property {Object}         attributes          - HTML attributes
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultCollapseData = {
  id: '',
  html: '',
  blockText: false,
  collapseHeight: null,
  extraClasses: '',
  maxItems: null,
  opened: false,
  childrenWrapper: null,
  disabled: false,
  collapseFrom: '',
  track: '',
  attributes: {}
}

export const CollapseTemplate = (d) => {
  d = { ...defaultCollapseData, ...d }
  return `
<div class="c-collapse is-checking ${d.opened ? 'in' : ''} 
    ${d.collapseFrom ? `c-collapse--from@${d.collapseFrom}` : ''} 
    ${d.extraClasses}"
     data-js-component="c-collapse"
     ${d.id ? `id="${d.id}"` : ''}
     ${d.collapseFrom ? `data-c-collapse__from="${d.collapseFrom}"` : ''}
     ${d.blockText ? `data-c-collapse__block-text="${d.blockText}"` : ''}
     ${d.collapseHeight ? `data-c-collapse__collapse-height="${d.collapseHeight}"` : ''}
     ${d.maxItems && (d.maxItems > 0) ? `data-c-collapse__max-items="${d.maxItems}"` : ''}
     ${d.childrenWrapper ? `data-c-collapse__children-wrapper=${d.childrenWrapper}` : ''}
     ${d.groupId ? `data-c-collapse__group-id=${d.groupId}` : ''}
     ${d.disabled ? 'data-c-collapse__disabled' : ''}
     ${d.track ? `data-track=${d.track}` : ''}
     ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.html}
</div>
`
}
