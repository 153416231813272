/**
 * The CollectionRawData contains all data needed to hydrate a Collection Row view
 *
 * @typedef {Object}          CollectionRowData
 *

 * @property {String}         html                - HTML snippet
 * @property {String}         expandedFrom       - Expanded from
 * @property {Boolean}        neutral
 * @property {Boolean}        collectionLabelHidden
 * @property {String}         tiledFrom           - Tiled from
 * @property {String}         extraClasses
 * @property {Object}         attributes          - HTML attributes
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultCollectionRowData = {
  html: '',
  expandedFrom: '',
  neutral: false,
  collectionLabelHidden: false,
  tiledFrom: '',
  extraClasses: '',
  attributes: {}
}

export const CollectionRowTemplate = d => {
  d = { ...defaultCollectionRowData, ...d }
  return `
<div class="c-collection-row  
    ${d.neutral ? 'c-collection-row--neutral' : ''}
    ${d.collectionLabelHidden ? 'c-collection-row--label-hidden' : ''}
    ${d.expandedFrom ? `c-collection-row--expanded-from@${d.expandedFrom}` : ''} 
    ${d.tiledFrom ? `c-collection-row--tiled-from@${d.tiledFrom}` : ''}
    ${d.extraClasses}"
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.html}
</div>
`
}
