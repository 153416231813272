
/**
 * The Dropdown Multiple Compact Item contains all data needed to hydrate a Dropdown Multiple when it is compact
 *
 * @typedef {Object}          DropdownMultipleCompactItemData
 *
 * @property {String}        text         - Option text
 * @property {String}        value        - Associated option's value
 */

export const defaultDropdownMultipleCompactItemData = {
  text: '',
  value: ''

}

export const DropdownMultipleCompactItemTemplate = (d) => {
  d = { ...defaultDropdownMultipleCompactItemData, ...d }
  return `<span class="c-dropdown__multiple-item" ${d.value ? `data-value="${d.value}"` : ''}">${d.text || ''}</span>
    `
}
