/**
 * The ChipData contains all data needed to hydrate a Chip view
 *
 * @typedef {Object}          ChipData
 *
 * @property {String}         text          - Chip text
 * @property {String}        [value]        - Associated value if it belongs to any other component
 * @property {Boolean}       [disabled]
 * @property {Boolean}       [highlighted]
 * @property {String}        [extraClasses] - Extra classes to append to the view
 * @property {Object}        [attributes]   - Chip's element attributes
 * @property {String}        [variant]      - Chip's variant
 * @property {Boolean}       [linkable]   - Chip is linkable
 * @property {String}        [link]       - Chip's link if linkable
 */

import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-chip__tokens.json')['c-chip']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/chip/data/c-chip__tokens.json`)['c-chip'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultChipData = {
  extraClasses: '',
  variant: 'default',
  text: '',
  value: undefined,
  disabled: false,
  highlighted: false,
  removable: true,
  linkable: false,
  mandatory: false,
  link: '',
  attributes: {}
}

export const ChipTemplate = (d) => {
  d = { ...defaultChipData, ...d }
  return `
<div class="c-chip 
    ${d.removable ? 'c-chip--removable' : ''}
    ${d.linkable ? 'c-chip--linkable' : ''}
    ${d.highlighted ? 'c-chip--highlighted' : ''}
    ${d.disabled ? 'is-disabled' : ''}
    ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''}
    ${d.mandatory ? 'c-chip--mandatory' : ''}
    ${d.extraClasses ? d.extraClasses : ''}"
  ${d.disabled ? 'disabled=""' : ''}
  ${d.value ? `data-value="${d.value}"` : ''}
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.linkable ? '' : `${d.text}`}
  ${d.removable
    ? '<button class="m-button--clean c-chip__icon m-icon m-icon--cross" data-c-chip__action="remove"></button>'
    : ''}
  ${d.linkable
    ? `<a class="c-chip__link" href="${d.link}">
        ${d.text}
        <span class="m-button--clean c-chip__icon m-icon m-icon--chevron-right"></span>
      </a>`
    : ''}
</div>
`
}
