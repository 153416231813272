import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import * as utilsHelper from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { getStyle, cssTimeToMs, forceRepaint } from '../../../js/document/css'
import { smooth } from '../../../js/document/scroll'
import { collapseEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'

const classNames = {
  opened: 'in',
  opening: 'is-opening',
  closing: 'is-closing',
  checking: 'is-checking'
}

const settings = {
  numberPattern: '{N}',
  resizeDelay: 200
}

const definition = {
  name: 'c-collapse',
  props: [{
    name: 'open',
    attr: '.in',
    type: 'boolean',
    defaultValue: false
  }, {
    name: 'maxItems',
    type: 'number',
    attr: 'data-c-collapse__max-items',
    defaultValue: 0
  }, {
    name: 'childrenWrapper',
    type: 'string',
    attr: 'data-c-collapse__children-wrapper',
    defaultValue: null
  },
  {
    name: 'groupId',
    type: 'string',
    attr: 'data-c-collapse__group-id',
    defaultValue: null
  },
  {
    name: 'disabled',
    type: 'boolean',
    attr: 'data-c-collapse__disabled',
    defaultValue: false
  },
  {
    name: 'scrollable',
    type: 'boolean',
    attr: 'data-c-collapse__scrollable',
    defaultValue: false
  },
  {
    name: 'scrollableSafeMargin',
    type: 'number',
    attr: 'data-c-collapse__scrollable--safe-margin',
    defaultValue: 0
  },
  {
    name: 'collapseFrom',
    type: 'string',
    attr: 'data-c-collapse__from',
    defaultValue: null
  }],
  actionElements: true
}

const attr = {
  track: 'data-track'
}

/**
 * Collapse content
 *
 */
export default class Collapse extends Component {
  /**
   * Creates a new Collapse behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.childrenWrapperElement = this.getProp('childrenWrapper')
      ? this.element.querySelector(this.getProp('childrenWrapper'))
      : this.element

    this.collapseHeight = element.getAttribute('data-c-collapse__collapse-height')
    this.collapseFrom = this.getProp('collapseFrom')

    this._buildEvents()
    this._getActionElements()
    this._getGroupCollapseElements()
    this._showHiddenItems(false)
    this._updateActionElements()
    this._updateHeight()
    this._updateActionElementsVisibility()
    this._updateActionElementsDisabled()

    element[this.name].open = this.open.bind(this)
    element[this.name].close = this.close.bind(this)
    element[this.name].toggle = this.toggle.bind(this)
    element[this.name].update = this.update.bind(this)
    element[this.name].isOpened = this.isOpened.bind(this)

    this.events.on('propChanged', (changes) => {
      if (changes.name === 'disabled') { this._updateActionElementsDisabled() }
    })

    this.eventData = this._getEventData()
    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
   * Set if content expands or collapses
   *
   * @param {boolean} opc - True for expanding; False for collapsing
   * @param options - Object with silent property: boolean
   */
  setOpen (opc, options) { return opc ? this._open(options) : this._close(options) }

  /**
   * Set the max number of items to be shown when collapsed
   *
   * @param {number} value - Number of items
   */
  setMaxItems (value) {
    this._setMaxItemsAttribute(value)
    if (!this.getProp('open')) { this._showHiddenItems(false) }
    this._updateActionElements()
    this._updateActionElementsVisibility()
  }

  /**
   * Expands the content
   */
  open (options) { return this.setProp('open', true, options) }

  /**
   * Collapses the content
   */
  close (options) { return this.setProp('open', false, options) }

  /**
   * Expand or collapses the content
   */
  toggle (options) { return this.getProp('open') ? this.close(options) : this.open(options) }

  /**
   * Updates collapse component
   */
  update () {
    this._updateActionElementsVisibility()
    return this.setMaxItems(this.getProp('maxItems'))
  }

  /**
  * Return if collapse is opened
  *
  * @returns {Boolean}
  */
  isOpened () {
    return this.getProp('open')
  }

  _getEventData () {
    const eventData = {}
    if (this.element) {
      eventData.attr = this.element.dataset
    }
    return eventData
  }

  _open (options = { silent: false }) {
    if (!options.silent) {
      this.events.emit(collapseEvents.OPEN, this.eventData)
    }
    this._updateActionElements()
    this._closeGroupCollapses()

    const transitionTime = cssTimeToMs(getStyle(this.element, 'transition-duration'))
    this.element.style.transitionDuration = '0'
    this.element.style.maxHeight = this.element.clientHeight + 'px'
    this.element.style.transitionDuration = null
    this._showHiddenItems()
    this.element.style.maxHeight = this.element.scrollHeight + 'px'
    this.element.classList.add(classNames.opening)
    const isScrollable = this.getProp('scrollable')
    const safeMargin = this.getProp('scrollableSafeMargin')
    this._showAllItemsOfCollapse(isScrollable, safeMargin)

    if (this.currentTransition) window.clearTimeout(this.currentTransition)
    this.currentTransition = setTimeout(() => {
      this.element.style.maxHeight = null
      this._updateActionElementsVisibility()
      this.element.classList.add(classNames.opened)
      this.element.classList.remove(classNames.opening)
      if (!options.silent) {
        this.events.emit(collapseEvents.OPENED, this.eventData)
      }
    }, transitionTime)
  }

  _close (options = { silent: false }) {
    if (!options.silent) {
      this.events.emit(collapseEvents.CLOSE, this.eventData)
    }
    this._updateActionElements()

    const transitionTime = cssTimeToMs(getStyle(this.element, 'transition-duration'))

    // Collapsing method affects how the element technically collapses, so do it by case
    this.element.style.transitionDuration = '0'
    this.element.style.maxHeight = this.element.scrollHeight + 'px'
    this.element.style.transitionDuration = null
    forceRepaint(this.element)

    this._showHiddenItems(false)
    this.element.style.maxHeight = (this._isByItems() ? this.element.scrollHeight : (this.collapseHeight) || 0) + 'px'
    this._showHiddenItems()
    this.element.classList.remove(classNames.opened)
    this.element.classList.add(classNames.closing)

    // Once transition is done, remove transitioning class, restore natural height and emit closed event
    if (this.currentTransition) window.clearTimeout(this.currentTransition)
    this.currentTransition = setTimeout(() => {
      this._showHiddenItems(false)
      this.element.style.maxHeight = null
      this._updateActionElementsVisibility()
      this.element.classList.remove(classNames.closing)
      if (!options.silent) {
        this.events.emit(collapseEvents.CLOSED, this.eventData)
      }
    }, transitionTime)
  }

  _showAllItemsOfCollapse (scrollable = false, safeMargin) {
    if (scrollable) {
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const collapsibleBoundingRect = this.element.getBoundingClientRect()
      let scroll
      if (documentScrollingElement.scrollTop + collapsibleBoundingRect.y + this.element.scrollHeight >= documentScrollingElement.scrollTop + documentScrollingElement.clientHeight) {
        scroll = documentScrollingElement.scrollTop + collapsibleBoundingRect.y - safeMargin
      } else {
        scroll = documentScrollingElement.scrollTop
      }
      smooth(documentScrollingElement, documentScrollingElement.scrollLeft, scroll)
    }
    return this
  }

  /**
   * Search for ActionElements on document.
   *
   * @returns {HTMLElement[]} Array of action elements.
   */
  _getActionElements () {
    this._actionElements = this._actionElements || (this.element.id
      ? Array.from(window.document.querySelectorAll(`[data-${this.name}__id="${this.element.id}"]:not(.${this.name})`))
      : []
    )
    return this._actionElements
  }

  /**
   * Search for Collapse components of same group on document.
   *
   * @returns {Collapse[]} Array of collapse components.
   */
  _getGroupCollapseElements () {
    this._groupCollapseElements = this._groupCollapseElements || (this.getProp('groupId')
      ? Array.from(window.document.querySelectorAll(`[data-${this.name}__group-id="${this.getProp('groupId')}"]`)).filter(element => element !== this.element)
      : null)
    return this._groupCollapseElements
  }

  _isByItems () { return (this.getProp('maxItems') > 0) }

  _setMaxItemsAttribute (value) {
    this.element[(value ? 'set' : 'remove') + 'Attribute'](`data-${this.name}__max-items`, value)
  }

  /**
   * Show more or less items when collapse depends on item's count
   *
   * @param {boolean} opc - if true, all items are shown
   */
  _showHiddenItems (opc = true) {
    const maxItems = this.getProp('maxItems')
    if (maxItems > 0) {
      Array.from(this.childrenWrapperElement.children)
        .forEach((element, index) => (element.style.display = (opc || (index < maxItems)) ? '' : 'none'))
    }
  }

  _updateHeight () {
    const finalFn = () => {
      this._updateActionElementsVisibility()
      this.element.classList.remove(classNames.checking)
    }
    if (this.getProp('open')) {
      this.element.classList.remove(classNames.opened)
      setTimeout(() => {
        this._height = this.element.clientHeight
        this.element.classList.add(classNames.opened)
        finalFn()
      }, this._transitionTime)
    } else { finalFn() }
  }

  _updateActionElements () {
    return this
      ._updateActionElementsState()
      ._updateActionElementsText()
  }

  _updateActionElementsDisabled () {
    this._actionElements.forEach(element => {
      element[this.getProp('disabled') ? 'setAttribute' : 'removeAttribute']('disabled', true)
    })
    return this
  }

  _updateActionElementsState () {
    this._actionElements.forEach(element => {
      element[this.getProp('open') ? 'setAttribute' : 'removeAttribute'](`data-${this.name}__opened`, true)
    })
    return this
  }

  _updateActionElementsVisibility () {
    let showActionElements = true
    const isBlockText = this.element.hasAttribute('data-c-collapse__block-text')
    if (this._isByItems()) {
      const hiddenItems = this.childrenWrapperElement.childElementCount - this.getProp('maxItems')
      showActionElements = (hiddenItems > 0)
    } else if (isBlockText && this.childrenWrapperElement.scrollHeight > 0) {
      if (this.childrenWrapperElement.scrollHeight < this.collapseHeight) {
        showActionElements = false
        this.element.classList.add('in')
      } else {
        this.element.style.minHeight = this.collapseHeight + 'px'
      }
    } else {
      if (!this.getProp('open')) { this._height = this.element.clientHeight }
      showActionElements = (this._height < this.element.scrollHeight)
    }
    this._actionElements.forEach((element) => { element.classList[showActionElements ? 'remove' : 'add']('is-hidden') })
    // Adds or remove the state class is-idle to in order to identify that the collapse is not needed at this moment
    if (!isBlockText) {
      this.element.classList[showActionElements ? 'remove' : 'add']('is-idle')
    }

    return this
  }

  _updateActionElementsText () {
    if (this._isByItems()) {
      const hiddenItems = this.childrenWrapperElement.childElementCount - this.getProp('maxItems')
      if (hiddenItems > 0) {
        this._actionElements.forEach((element) => {
          const textPattern = element.getAttribute(`data-${this.name}__text-pattern`)
          if (textPattern) element.innerText = textPattern.replace(settings.numberPattern, hiddenItems.toString())
        })
      }
    }
    return this
  }

  _closeGroupCollapses () {
    if (this._groupCollapseElements) {
      this._groupCollapseElements.forEach(element => {
        const component = element[this.name]
        if (component) {
          component.close()
        }
      })
    }
  }

  _buildEvents () {
    this._events = [[window, { resize: utilsHelper.debounce(() => this.update(), settings.resizeDelay) }]]
    domEventsHelper.attachEvents(this._events, definition.name)
    return this
  }
}

registerComponent(Collapse, definition.name, definition)
