/**
 * The Dropdown Single Option contains all data needed to hydrate a Dropdown Single
 *
 * @typedef {Object}            DropdownData
 *
 * @property {String}           [id]                  Form element id
 * @property {String}           [placeholder]         Form element placeholder
 * @property {Boolean}          [disabled]            When true any change cannot be done
 * @property {Boolean}          [icon]
 * @property {Object}           [options]             all optins for the dropdown to build
 */
import { encodeAttributes } from '../../../js/helpers/string'
import { BtnTemplate, defaultButtonData } from '../btn/c-btn.template'
import { DropdownSingleOptionTemplate } from './c-dropdown_single-option.template'

export const defaultDropdownSingleData = {
  id: '',
  placeholder: '',
  disabled: false,
  variant: 'default',
  icon: false,
  options: []
}

export const DropdownSingleTemplate = (d) => {
  d = { ...defaultDropdownSingleData, ...d }
  return `<div class="c-dropdown__wrapper">
      ${d.variant === 'compact' ? `<label class="c-dropdown__label e-form-label"for="${d.id}" data-c-dropdown__label>${d.label}</label>` : ''}
      ${d.icon ? `<span class="c-dropdown__icon m-icon--${d.icon}"></span>` : ''}

      <select class="c-dropdown__element" data-dropdown-select
              name="${d.id}" id="${d.id}" ${d.disabled ? 'disabled' : ''}
               ${d.elementAttributes ? encodeAttributes(d.elementAttributes) : ''}>
        
        ${d.placeholder
? `<option class="c-dropdown__option-default" value="" disabled selected>${d.placeholder}</option>`
          : (d.variant === 'compact' && d.label) ? `<option class="c-dropdown__option-default" value="" disabled selected>${d.label}</option>` : ''
        }
        ${d.options.map(option => DropdownSingleOptionTemplate(option)).join('')}
      </select>
      ${d.hasClearButton
      ? BtnTemplate({
        ...defaultButtonData,
        ...{
          text: '',
          variant: 'icon-only',
          icon: 'cross',
          iconPosition: 'left',
          extraClasses: 'c-dropdown__clear',
          attributes: d.clearButtonAttributes
        }
      })
      : ''}
      <span class="c-dropdown__arrow m-icon m-icon--chevron-down"></span>
    </div>`
}
