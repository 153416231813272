
export const da = {
  dayPlaceholder: 'Dag',
  monthPlaceholder: 'Måned',
  yearPlaceholder: 'År'
}

export const de = {
  dayPlaceholder: 'Tag',
  monthPlaceholder: 'Monat',
  yearPlaceholder: 'Jahr'
}

export const en = {
  dayPlaceholder: 'Day',
  monthPlaceholder: 'Month',
  yearPlaceholder: 'Year'
}

export const nb = {
  dayPlaceholder: 'Dag',
  monthPlaceholder: 'Måned',
  yearPlaceholder: 'År'
}
export const fr = {
  dayPlaceholder: 'Jour',
  monthPlaceholder: 'Mois',
  yearPlaceholder: 'Année'
}

export const nl = {
  dayPlaceholder: 'Dag',
  monthPlaceholder: 'Maand',
  yearPlaceholder: 'Jaar'
}

export const sv = {
  dayPlaceholder: 'Dag',
  monthPlaceholder: 'Månad',
  yearPlaceholder: 'År'
}
