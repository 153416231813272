import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { elementFromString } from '../../../js/document/html-helper'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { DropdownSingleOptionTemplate } from './c-dropdown_single-option.template'
import { DropdownMessagesTemplate } from './c-dropdown__messages.template'
import registeredEvents from '../../../js/helpers/registered-events'
import { requestAnimationFrame } from '../../../js/document/requestAnimationFrame'

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const attr = {
  track: 'data-track'
}

const componentQueries = {
  arrow: '.c-dropdown__arrow'
}

const definition = {

  name: 'c-dropdown',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'value',
      type: 'string'
    }, {
      name: 'options',
      type: 'collection'
    }, {
      name: 'placeholder',
      type: 'string',
      attr: 'data-c-dropdown__placeholder'
    }, {
      name: 'disabled',
      type: 'boolean',
      attr: '.is-disabled',
      defaultValue: false
    }, {
      name: 'state',
      type: 'string',
      attr: '.has-',
      allowedValues: [
        '',
        'success',
        'warning',
        'error'
      ],
      defaultValue: ''
    },
    {
      name: 'label',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'sync-selected',
      type: 'boolean',
      attr: 'data-sync-selected-on-load',
      defaultValue: false
    },
    {
      name: 'saveOnDomClick',
      type: 'boolean',
      attr: 'data-save-dom-click',
      defaultValue: false
    }
  ]

}

/**
 * Dropdown content
 *
 */
export default class Dropdown extends Component {
  /**
   * Creates a new dropdown behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.silentErrors = true
    this.opened = false

    this.arrow = this.element.querySelector(componentQueries.arrow)

    const syncSelectedOnLoad = this.getProp('sync-selected')
    if (syncSelectedOnLoad) {
      requestAnimationFrame(() => this.syncSelected())
    }

    const clearNode = this._getClearNode(element)
    if (clearNode) {
      clearNode.addEventListener('click', (e) => {
        const newValue = ''
        if (this.props.value !== newValue) this.setProp('value', newValue)
      })
    }

    this.isRequired = this.element.hasAttribute('required')
    this.messagesElement = this.element.querySelector('.c-dropdown__messages')
    this.messageRequired = null
    if (element.getAttribute('data-message-required')) {
      this.messageRequired = element.getAttribute('data-message-required')
    } else {
      this.messageRequired = this.getSelectNode().getAttribute('data-message-required')
    }
    this.labelElement = this.element.querySelector('.c-dropdown__label')

    this.element[definition.name].validate = this.validate.bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)
    this.element[definition.name].getValue = this.getValue.bind(this)
    this.element[definition.name].open = (this.open).bind(this)
    this.element[definition.name].getPlaceholder = this.getPlaceholder.bind(this)
    this.element[definition.name].getLabel = this.getLabel.bind(this)
    this.element[definition.name].getSelectNode = this.getSelectNode.bind(this)

    const selectNode = this.getSelectNode(element)

    domEventsHelper.attachEvents([
      [selectNode, { change: (ev) => this.onChange(ev) }],
      [selectNode, { click: (ev) => this.onClick(ev) }],
      [selectNode, { focusout: (ev) => this.onFocusOut(ev) }]
    ], definition.name)

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  onChange (ev) {
    this.setProp('value', ev.target.value)
    this.close()
  }

  onClick () {
    if (this.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  onFocusOut () {
    if (this.opened) {
      this.close()
    }
  }

  open () {
    this.opened = true
    this.updateChevron()
  }

  close () {
    this.opened = false
    this.updateChevron()
  }

  updateChevron () {
    if (this.opened) {
      this.arrow.classList.remove('m-icon--chevron-down')
      this.arrow.classList.add('m-icon--chevron-up')
    } else {
      this.arrow.classList.remove('m-icon--chevron-up')
      this.arrow.classList.add('m-icon--chevron-down')
    }
  }

  getValue () {
    return this.getSelectNode(this.element).value
  }

  getPlaceholder () {
    return this.element.dataset.cDropdown__placeholder
  }

  async setValue (value, options = {}) {
    const optionCandidate = value ? this.getSelectNode(this.element).querySelector(`[value="${value}"]`) : this.getSelectNode(this.element).firstChild
    if (optionCandidate) {
      this.getSelectNode(this.element).value = value
      this.element.classList[value ? 'add' : 'remove']('has-value')
      if (!options.stopPropagation) {
        this.setProp('options', this.props.options.map(o => ({ ...o, selected: o.value === value })), { ...options, stopPropagation: true })
      }

      if (!options.silent) {
        this.events.emit('change', value)
      }
      return value
    } else {
      this.element.classList.remove('has-value')
      throw new Error(`${value} is not a valid option for`, this.element)
    }
  }

  /**
   * Get the whole array of options from current DOM
   *
   * @return {HTMLElement[]} The parsed options data
   */
  getOptions () {
    return Array.from(this.getSelectNode().options)
      .filter((option) => option.classList.contains('c-dropdown__option'))
      .reduce((array, option) => ([...array, {
        text: option.text,
        value: option.value,
        selected: option.selected,
        disabled: option.disabled
      }]), [])
  }

  /**
   * Set the array of options the dropdown will have
   * If option selected is provided, that will be set, else, the previous value before the options changed
   *
   * @param {Object[]} items - Items to be set
   * @param {Object} options - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   */
  setOptions (items, options = {}, oldItems) {
    items = items || []
    Array.from(this.getSelectNode().options).forEach((option) => option.remove())

    const placeholder = this.getProp('placeholder')
    if (placeholder && placeholder !== '') {
      const noDefaultItems = items.filter((item) => !item.default)
      items = [{
        default: true,
        text: placeholder,
        selected: (items.find((item) => item.default) || {}).selected || !noDefaultItems.some(item => item.selected)
      }, ...noDefaultItems]
    }

    const itemsToElements = (items) => {
      const fragment = document.createDocumentFragment()
      items.forEach(item => {
        if (item.default) { item = { ...item, value: '', disabled: true } }
        fragment.appendChild(elementFromString(DropdownSingleOptionTemplate(item)))
      })
      return fragment
    }

    this.getSelectNode().appendChild(itemsToElements(items))

    if (!options.stopPropagation) {
      const selectedOption = this.props.options.find(o => o.selected)
      const selectedValue = selectedOption ? selectedOption.value : ''
      this.setProp('value', selectedValue, { ...options, stopPropagation: true })
    }

    if (!options.silent) {
      this.events.emit('changedOptions', this.props.options, oldItems)
    }
  }

  getSelectNode () {
    this.selectNode = this.selectNode || this.element.querySelector('.c-dropdown__element')
    return this.selectNode
  }

  _getClearNode () {
    this.clearNode = this.clearNode || this.element.querySelector('.c-dropdown__clear')
    return this.clearNode
  }

  setDisabled (value) {
    const selectElement = this.element.querySelector('[data-dropdown-select]')
    if (value) {
      this.element.classList.add('is-disabled')
      if (selectElement) selectElement.setAttribute('disabled', '')
    } else {
      this.element.classList.remove('is-disabled')
      if (selectElement) selectElement.removeAttribute('disabled', '')
    }
  }

  enableErrors () {
    this.silentErrors = false
  }

  validate (validateOnly = false) {
    const isEmptyAndRequired = this.getValue() === '' && this.isRequired
    let isValid = this.getSelectNode().validity.valid
    if (isEmptyAndRequired) {
      isValid = false
    }
    let messages = []
    let messageTypes = []
    if (!validateOnly && !this.silentErrors) {
      if (!isValid) {
        messages = this._getValidationMessages()
        messageTypes = this._getValidationMessageTypes()
      }
      this._styleValidity(isValid, messages)
    }
    return {
      isValid,
      errorMessages: messages,
      errorTypes: messageTypes,
      fieldName: this.getSelectNode(this.element).name
    }
  }

  /**
  * Set the label text
  */
  setLabel (text) {
    if (this.labelElement) {
      this.labelElement.innerText = text
    }
  }

  /**
  * Get the label text
  */
  getLabel () {
    return this.labelElement ? this.labelElement.innerText : ''
  }

  syncSelected () {
    const selectedOption = this.element.querySelector('[selected]')
    if (selectedOption && selectedOption.value) {
      this.getSelectNode().value = selectedOption.value
      this.setProp('value', selectedOption.value)
    }
  }

  _getValidationMessages () {
    const messages = []
    const validity = this.getValue()
    if (validity === '' && this.messageRequired) {
      messages.push(this.messageRequired)
    }
    return messages
  }

  _getValidationMessageTypes () {
    const messageTypes = []
    const validity = this.getValue()
    if (validity === '' && this.messageRequired) {
      messageTypes.push('ValueMissing')
    }

    return messageTypes
  }

  _styleValidity (isValid, messages) {
    if (!isValid) {
      this.element.classList.add('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = DropdownMessagesTemplate(messages)
      }
    } else {
      this.element.classList.remove('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ''
      }
    }
  }
}

registerComponent(Dropdown, definition.name, definition)
