import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { newGUID } from '../../../js/helpers/guid'
import { ModalTemplate } from '../modal/c-modal.template'
import { BtnTemplate } from '../btn/c-btn.template'
import { elementFromString } from '../../../js/document/html-helper'
import { favoriteEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
import favorites from './main'

const definition = {
  name: 'c-favorite-btn',
  props: [
    {
      name: 'active',
      type: 'boolean',
      attr: '.is-active'
    },
    {
      name: 'itemId',
      type: 'string',
      attr: 'data-favorite__item-id'
    },
    {
      name: 'publicationCode',
      type: 'string',
      attr: 'data-favorite__publication-code'
    },
    {
      name: 'parentContextId',
      type: 'string',
      attr: 'data-favorite__parent-contextId'
    }
  ]
}
const ELEMENT_QUERIES = {
  trackAttr: 'data-track'
}
const fallbackTexts = {
  title: 'Log in to add favourites',
  body: 'You need to be logged in to add a favourite',
  loginBtn: 'Login',
  footer: "Don't have an account?",
  footerBtn: 'Create an account'
}

/**
 * FavoriteButton content
 */
export default class FavoriteButton extends Component {
  /**
   * Creates a new FavoriteCollector behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.staticData = this.getStaticData()
    // Update active prop with current favorites
    favorites.events.on('init', () => {
      this.setProp('active', favorites.hasItem(this.props.itemId), { silent: true })
    })
    // Bind click event
    element.addEventListener('click', async (ev) => {
      if (favorites.shouldDisplayModal()) {
        this.openModal()
      } else {
        let itemToggled
        const contextItemId = this.getProp('parentContextId')
        let eventCallback
        if (this.props.active) {
          itemToggled = await favorites.removeItemAsync(this.props.itemId, contextItemId, this.props.publicationCode)
          eventCallback = () => this.events.emit(favoriteEvents.FAVORITE_REMOVE, { id: this.getProp('itemId'), staticData: this.staticData })
        } else {
          itemToggled = await favorites.addItemAsync(this.props.itemId, contextItemId, this.props.publicationCode)
          eventCallback = () => this.events.emit(favoriteEvents.FAVORITE_ADD, { id: this.getProp('itemId'), staticData: this.staticData })
        }

        if (itemToggled) {
          this.setProp('active', !this.props.active, { silent: true })
          eventCallback()
        } else if (favorites.shouldDisplayModal()) {
          this.openModal()
        }
      }
    })

    registeredEvents.registerWidgetEvents(definition.name, this.events, {
      ...this.element.hasAttribute(ELEMENT_QUERIES.trackAttr) && { track: this.element.attributes[ELEMENT_QUERIES.trackAttr].value }
    })
  }

  openModal () {
    if (!this.modal) {
      this.modal = elementFromString(this.renderModal())
      document.body.appendChild(this.modal)
      Component.initDocumentComponentsFromAPI(this.modal)
      this.attachModalEvents(this.modal['c-modal'])
    }
    this.modal['c-modal'].open()
  }

  renderModal () {
    const id = newGUID()
    const modalId = `${id}_modal`
    return `${ModalTemplate({
      id: modalId,
      size: 'small',
      title: favorites.modalTitle || fallbackTexts.title,
      style: 'neutral',
      attributes: { 'data-c-favorite__modal': '' },
      bodyContent: this.getModalBody(),
      footerContent: this.getModalFooter(),
      extraClasses: 'c-modal__favorites',
      headerSize: 'medium'
    })}`
  }

  getModalBody () {
    return `<div class='c-modal__favorites-body'>
    ${favorites.modalBodyText || fallbackTexts.body}
    </div>`
  }

  getModalFooter () {
    const loginUrl = `${favorites.loginUrl()}?returnurl=${encodeURIComponent(document.URL)}`
    return `<div class='c-modal__favorites-footer-container'>
    ${BtnTemplate({
      link: {
        href: loginUrl,
        title: 'Login'
      },
      text: favorites.modalLoginButtonText || fallbackTexts.loginBtn,
      variant: 'flow',
      extraClasses: 'c-modal__favorites-login-btn'
    })}
    <div class='c-modal__favorites-footer'>
    <h5 class='c-modal__favorites-footer-text'>${favorites.modalFooterText || fallbackTexts.footer}</h5>
    ${BtnTemplate({
      link: {
        href: loginUrl,
        title: 'Login'
      },
      variant: 'flat-neutral',
      text: favorites.modalFooterLinkText || fallbackTexts.footerBtn,
      extraClasses: 'c-modal__favorites-signup-btn'
    })}
    </div>
    </div>`
  }

  attachModalEvents (modalApi) {
    modalApi.events.on('open', () => {
      this.events.emit(favoriteEvents.FAVORITE_MODAL_OPEN, { id: this.getProp('itemId'), staticData: this.staticData })
    })
    modalApi.events.on('close', () => {
      this.events.emit(favoriteEvents.FAVORITE_MODAL_CLOSE, { id: this.getProp('itemId'), staticData: this.staticData })
    })

    const modalSignUpElement = this.modal.querySelector('.c-modal__favorites-signup-btn')
    modalSignUpElement.addEventListener(
      'click', () => this.events.emit(favoriteEvents.FAVORITE_SIGNUP, { id: this.getProp('itemId'), staticData: this.staticData }))

    const modalLoginElement = this.modal.querySelector('.c-modal__favorites-login-btn')
    modalLoginElement.addEventListener(
      'click', () => this.events.emit(favoriteEvents.FAVORITE_LOGIN, { id: this.getProp('itemId'), staticData: this.staticData }))
  }

  getStaticData () {
    const staticDataElement = document.querySelector('.w-static-data-content')
    let data = null
    if (staticDataElement) {
      data = staticDataElement.dataset
        ? {
            id: staticDataElement.dataset.wStaticContentDataId,
            name: staticDataElement.dataset.wStaticContentDataName,
            countryId: staticDataElement.dataset.wStaticContentDataAccoCountryId,
            cityId: staticDataElement.dataset.wStaticContentDataAccoRegionId,
            rating: staticDataElement.dataset.wStaticContentDataRating,
            badges: staticDataElement.dataset.wStaticContentDataBadges,
            category: staticDataElement.dataset.wStaticContentDataCategory,
            location: staticDataElement.dataset.wStaticContentDataLocationId,
            accoStarts: staticDataElement.dataset.wStaticContentDataAccoStars
          }
        : null
    }
    return data
  }
}

registerComponent(FavoriteButton, definition.name, definition)
