
/**
 * The Dropdown Single Option contains all data needed to hydrate a Dropdown Single
 *
 * @typedef {Object}          DropdownSingleOptionData
 *
 * @property {String}        text         - Option text
 * @property {String}        value        - Associated option's value
 * @property {String}        [count]      - Extra string for option
 * @property {Boolean}       [default]    - Is option default value?
 * @property {Boolean}       [disabled]
 * @property {Boolean}       [selected]
 */

export const defaultDropdownSingleOptionData = {
  text: '',
  value: '',
  count: undefined,
  default: false,
  disabled: false,
  selected: false
}

export const DropdownSingleOptionTemplate = (d) => {
  d = { ...defaultDropdownSingleOptionData, ...d }
  return `<option class="${d.default ? 'c-dropdown__option-default' : 'c-dropdown__option'}"
          ${d.disabled ? 'disabled' : ''}
          ${d.selected ? 'selected' : ''}
          value="${d.value}">${d.text || ''} ${d.count ? `(${d.count})` : ''}</option>
  `
}
