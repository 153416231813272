/**
 * The Dropdown Single Option contains all data needed to hydrate a Dropdown Single
 *
 * @typedef {Object}          DateSelectorData
 *
 * @property {String}        [extraClasses]
 * @property {Boolean}       [blockModifier]
 * @property {DateString}    [date]                               - Current date
 * @property {DateString}    [minDate]                            - Min date selectable
 * @property {DateString}    [maxDate]                            - Max date selectable
 * @property {Boolean}       [disabled]
 * @property {String[]}      [validations]                        - Validations to bear in mind (isReal, isInBounds)
 * @property {DateString}    [monthType]                          - Short (Jan, Feb...) or default (January, february)
 * @property {Boolean}       [hiddenMainLabel]                    - True if label should be hidden
 * @property {String}        [mainLabel]
 * @property {Object}        [attributes]
 * @property {Object}        [dropdownConfig]
 * @property {String}        [dropdownConfig.placeholder]
 * @property {Boolean}       [dropdownConfig.showDefaultOption]
 * @property {Boolean}       [dropdownConfig.state]
 * @property {Boolean}       [dropdownConfig.highlighted]
 * @property {Boolean}       [dropdownConfig.hiddenLabel]
 * @property {String}        [dropdownConfig.hasClearButton]
 * @property {Boolean}       [dropdownConfig.icon]
 * @property {Object}        [tooltip]                              - data for the tooltip label
 */

import uid from '../../../js/core/uid'
import { DropdownTemplate } from '../dropdown/c-dropdown.template'
import { TooltipTemplate, defaultTooltipData } from '../../components/tooltip/c-tooltip.template'
import { encodeAttributes } from '../../../js/helpers/string'

import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { DateSelectorMessagesTemplate } from './c-date-selector__messages.template'
const componentLocales = register(`window.sundio.i18n.${language}.dateSelector`)

export const defaultDateSelectorData = {
  id: null,
  extraClasses: '',
  blockModifier: false,
  date: null,
  minDate: null,
  maxDate: null,
  disabled: false,
  validations: ['isReal', 'isInBounds'],
  monthType: 'default',
  hiddenMainLabel: false,
  mainLabel: '',
  dayPlaceholder: componentLocales.dayPlaceholder,
  monthPlaceholder: componentLocales.monthPlaceholder,
  yearPlaceholder: componentLocales.yearPlaceholder,
  attributes: {},
  messageInvalid: '',
  messageRequired: '',
  messageOutOfBounds: '',
  dropdownConfig: {
    placeholder: '',
    showDefaultOption: true,
    state: '',
    highlighted: false,
    hiddenLabel: true,
    hasClearButton: false,
    icon: false,
    clearButtonAttributes: {
      'data-c-dropdown__clear': ''
    }
  },
  hideRequiredSymbol: false,
  tooltip: null
}

export const DateSelectorTemplate = (d) => {
  d = { ...defaultDateSelectorData, ...d }
  d.id = d.id || 'my-date-selector' + uid()
  return `<div class="c-date-selector
            ${d.required ? 'is-required' : ''}
            ${d.blockModifier ? 'c-date-selector--block' : ''}
            ${d.hideRequiredSymbol ? 'c-date-selector--without-required-symbol' : ''}
            ${d.extraClasses || ''}"
        id=${d.id}
        data-js-component="c-date-selector"
        ${d.date ? `data-c-date-selector__date="${d.date}"` : ''}
        ${d.minDate ? `data-c-date-selector__min-date="${d.minDate}"` : ''}
        ${d.state ? 'has-{d.state}' : ''}
        ${d.validations ? `data-c-date-selector__validations="${d.validations.join(',')}"` : ''}
        ${d.maxDate ? `data-c-date-selector__max-date="${d.maxDate}"` : ''}
        ${d.monthType ? `data-c-date-selector__month-type="${d.monthType}"` : ''}
        ${d.required ? 'required' : ''}
        ${d.messageRequired && d.messageRequired !== '' ? `data-message-required="${d.messageRequired}"` : ''}
        ${d.messageInvalid && d.messageInvalid !== '' ? `data-message-invalid="${d.messageInvalid}"` : ''}
        ${d.messageOutOfBounds && d.messageOutOfBounds !== '' ? `data-message-out-of-bounds="${d.messageOutOfBounds}"` : ''}
        ${d.attributes ? encodeAttributes(d.attributes) : ''}>

      ${d.hiddenMainLabel
    ? `<label aria-hidden="true" aria-label="${d.mainLabel}" for="${d.id + '__day'}"></label>`
    : `${d.mainLabel
      ? `${d.tooltip ? '<div class="e-form-label__container">' : ''}
          <label for="${d.id + '__day'}" class="c-date-selector__label e-form-label">${d.mainLabel}</label>
          ${d.tooltip ? `${TooltipTemplate({ ...defaultTooltipData, ...d.tooltip })} </div>` : ''}`
      : ''}
      `}

    <div class="c-date-selector__body">
      ${DropdownTemplate({
        ...d.dropdownConfig,
        id: `${d.id}__day`,
        placeholder: d.dayPlaceholder,
        disabled: d.disabled,
        attributes: { 'data-c-date-selector__type': 'day' }
      })}
      ${DropdownTemplate({
        ...d.dropdownConfig,
        id: `${d.id}__month`,
        placeholder: d.monthPlaceholder,
        disabled: d.disabled,
        attributes: { 'data-c-date-selector__type': 'month' }
      })}
      ${DropdownTemplate({
        ...d.dropdownConfig,
        id: `${d.id}__year`,
        placeholder: d.yearPlaceholder,
        disabled: d.disabled,
        attributes: { 'data-c-date-selector__type': 'year' }
      })}
    </div>
    <div class="c-date-selector__messages">
      ${DateSelectorMessagesTemplate(d.messages)}
    </div>
  </div>`
}
