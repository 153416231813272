/**
 * The CollapseTriggerTemplate contains all data needed to hydrate a Collapse selector view
 *
 * @typedef {Object}          CollapseTriggerTemplate
 *
 * @property {String}         id                  - Collapse Trigger's id
 * @property {String}         action              - Action to be done
 * @property {String}         extraClasses        - ExtraClasses if needed
 * @property {Number}         smooth              - Smooth
 * @property {Boolean}        textPattern         - Text pattern
 * @property {Boolean}        text                - Text shown
 * @property {Object}         arrow               - Arrow of collapse
 */
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-collapse__tokens.json')['c-collapse']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/collapse/data/c-collapse__tokens.json`)['c-collapse'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultCollapseTriggerData = {
  id: '',
  action: '',
  extraClasses: '',
  smooth: false,
  textPattern: '',
  text: '',
  arrow: false,
  ariaLabel: ''
}

export const CollapseTriggerTemplate = (d) => {
  d = { ...defaultCollapseTriggerData, ...d }
  return `
    <button class="m-button--clean c-collapse__trigger is-hidden ${d.smooth ? 'c-collapse__trigger--smooth' : ''} ${d.extraClasses}"
      ${d.id ? `data-c-collapse__id=${d.id}` : ''}
      ${d.action ? `data-c-collapse__action=${d.action}` : ''}
      ${d.textPattern ? `data-c-collapse__text-pattern=${d.textPattern}` : ''}
      ${d.ariaLabel ? `aria-label=${d.ariaLabel}` : ''}>
      ${d.text
        ? `<span class="c-collapse__trigger-text">${d.text}</span>`
        : ''
      }
      ${d.arrow
        ? `<span class="m-icon m-icon--size-large m-icon--${getTokenClass('trigger-icons', 'down', tokens)} c-collapse__trigger-icon"></span>`
        : ''
      }
   </button>
`
}
