import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { forceRepaint, getStyle, cssTimeToMs } from '../../../js/document/css'
/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {

  name: 'c-floating-box',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'opened',
      type: 'boolean',
      defaultValue: 'false'
    }, {
      name: 'minHeight',
      type: 'string',
      attr: 'data-c-floating-box__min-height',
      defaultValue: ''
    }, {
      name: 'maxHeight',
      type: 'string',
      attr: 'data-c-floating-box__max-height',
      defaultValue: ''
    }
  ]

}

/**
 * FloatingBox content
 *
 */
export default class FloatingBox extends Component {
  /**
   * Creates a new FloatingBox behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.element[definition.name].adjustSize = this.adjustSize.bind(this)
    this.element[definition.name].forceClosedCallback = this._closed.bind(this)
    window.addEventListener('resize', this.adjustSize())
  }

  getOpened () {
    return this.isOpened !== undefined ? this.isOpened : this.element.classList.contains('in')
  }

  setOpened (opened) {
    return this[opened ? 'open' : 'close']()
  }

  open () {
    return new Promise((resolve) => {
      this.adjustSize()
      if (this.isOpened) resolve()
      this.element.classList.add('is-opening')
      forceRepaint(this.element)
      this.element.classList.add('in')

      this.transitionTimeout(() => {
        this.element.classList.remove('is-opening')
        this.isOpened = true
        resolve()
      })
    })
  }

  close () {
    return new Promise((resolve) => {
      this.isOpened = false
      this.element.classList.add('is-closing')
      this.element.classList.remove('is-opening')
      this.element.classList.remove('in')
      this.closedResolve = resolve
      this.closedBound = this.closedBound || this._closed.bind(this)
      this.transitionTimeout(this.closedBound)
    })
  }

  _closed () {
    this.element.classList.remove('is-closing')
    if (this.closedResolve && typeof this.closedResolve === 'function') this.closedResolve()
    delete this.closedResolve
  }

  transitionTimeout (cb) {
    const transitionTime = cssTimeToMs(getStyle(this.element, 'transition-duration'))
    if (this.currentTransition) window.clearTimeout(this.currentTransition)
    this.currentTransition = setTimeout(() => {
      cb()
    }, transitionTime)
  }

  adjustSize () {
    const minHeight = this.getProp('minHeight')
    const maxHeight = this.getProp('maxHeight')

    const bodyNode = this._getBodyNode()
    const isOpened = this.element.classList.contains('in')

    if (getStyle(this.element, 'position') === 'fixed') {
      // Fixed position does not need to deal with min and max heights
      // Remove overridden properties if position is fixed
      bodyNode.style.height = null
      this.element.style.right = null
      this.element.style.left = null
    } else {
      // NonFixed position needs to deal with min and max heights, horizontal overflow as well

      // Force a transparent display to get the computed heights
      bodyNode.style.height = maxHeight || null
      this.element.style.opacity = '0'
      this.element.style.right = null
      this.element.style.left = null
      if (!isOpened) this.element.classList.add('in')
      forceRepaint(this.element)

      // Fix horizontal overflow
      let elementRect = this.element.getBoundingClientRect()
      const horizontalOverflow = (elementRect.right < 0 || (elementRect.right > window.innerWidth))
      this.element.style.right = horizontalOverflow ? '0' : null
      this.element.style.left = horizontalOverflow ? '0' : null

      // Fix vertical sizes
      if (minHeight && maxHeight) {
        // Get header and footer heights to adjust a more real height for the body
        const headerNode = this._getHeaderNode()
        const footerNode = this._getFooterNode()
        const contentNode = this._getContentNode()
        const headerHeight = headerNode ? parseInt(headerNode.getBoundingClientRect().height) : 0
        const footerHeight = footerNode ? parseInt(footerNode.getBoundingClientRect().height) : 0
        const contentHeight = contentNode ? parseInt(contentNode.getBoundingClientRect().height) : 0
        let idealMaxHeight = parseInt(maxHeight) - headerHeight - footerHeight
        idealMaxHeight = contentHeight < idealMaxHeight ? contentHeight : idealMaxHeight
        bodyNode.style.height = `${idealMaxHeight}px`
        forceRepaint(this.element)

        // Get other numbers to make final calculations
        const viewportHeight = (window.innerHeight || document.documentElement.clientHeight)
        const edgeMargin = 20
        elementRect = this.element.getBoundingClientRect()

        if ((viewportHeight - edgeMargin) < elementRect.bottom) {
          // Overflows, a better height is necessary
          let betterHeight = parseInt(viewportHeight - elementRect.top - edgeMargin)
          betterHeight = betterHeight >= parseInt(minHeight) ? betterHeight : parseInt(minHeight)
          bodyNode.style.height = `${betterHeight - headerHeight - footerHeight}px`
        }
      }

      // Restore previous properties
      if (!isOpened) this.element.classList.remove('in')
      this.element.style.opacity = null
      this.element.style.transitionDuration = null
      forceRepaint(this.element)
    }

    return this
  }

  _getBodyNode () {
    return this.element.querySelector('.c-floating-box__body')
  }

  _getContentNode () {
    return this.element.querySelector('.c-floating-box__body-content')
  }

  _getHeaderNode () {
    return this.element.querySelector('.c-floating-box__header')
  }

  _getFooterNode () {
    return this.element.querySelector('.c-floating-box__footer')
  }
}

registerComponent(FloatingBox, definition.name, definition)
